import React from "react"
import { connect, useSelector } from "react-redux"
import { 
  // Auth 
} from "aws-amplify"
import {
  // loginStatus,
  spinner,
  resetConfirm,
  authFailure,
} from "../state/actions"
import { LogInWrapper } from "../elements"
import {Messenger} from "../components"

const ResetConfirm = ({ dispatch, isDark, locale }) => {
  // console.log(`reset confirm`)
  let lingua = require("../utils/lingua")(locale)("Auth")
  var inputs = {}
  const setSpinner = { visible: true, text: "...sending confirmation." }
  const userID = useSelector(state => state.resetStarted).userID

  const handleConfirmClick = async evt => {
    evt.preventDefault()
    thunkConfirm()
  }

  const thunkConfirm = () => {
    if (validateConfirmation()) {
      dispatch(spinner(setSpinner))
      dispatch(
        resetConfirm({
          userID: userID,
          code: inputs.code,
          password: inputs.password,
        })
      )
    } else {
      dispatch(
        authFailure({
          visible: true,
          message:
            "Form does not validate. Please make sure your passwords match, and you have entered the verification code from your email.",
        })
      )
    }
  }

  const validateConfirmation = () => {
    try {
      return (
        inputs.password.length > 0 &&
        inputs.password === inputs.passwordReset &&
        inputs.code.length > 0
      )
    } catch (error) {
      return false
    }
  }

  const handleInputChange = evt => {
    const { name, value, type, checked } = evt.target
    const check_type = ["radio", "checkbox"].includes(type)
    inputs[name] = check_type ? checked : value
    inputs["checkedValue"] = check_type ? value : null
  }

  const renderConfirmation = () => {
    return (
      <LogInWrapper isDark={isDark}>
        <form onSubmit={handleConfirmClick}>
          <div className="input1">
            <input
              className="rounded-input"
              type="tel"
              id="code"
              key="code"
              name="code"
              onChange={handleInputChange}
              placeholder={lingua.confirmationCode}
            />
          </div>
          <p>Please check your email for the confirmation code.</p>
          <hr />
          <div className="input2">
            <input
              className="rounded-input"
              type="password"
              id="password"
              key="password"
              name="password"
              onChange={handleInputChange}
              placeholder={lingua.newPassword}
            />
          </div>
          <div className="input2">
            <input
              className="rounded-input"
              type="password"
              id="passwordReset"
              key="passwordReset"
              name="passwordReset"
              onChange={handleInputChange}
              placeholder={lingua.confirmPassword}
            />
          </div>
          <div className="input3">
            <button
              className="form-button"
              type="submit"
              // disabled={!validateConfirmation()}
            >
              {lingua.confirm}
            </button>
          </div>
        <Messenger isDark={isDark} />
        </form>

      </LogInWrapper>
    )
  }
  return renderConfirmation()
}

const mapStateToProps = state => ({ dashboard: state.dashboard })
export default connect(state => mapStateToProps)(ResetConfirm)
