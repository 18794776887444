const storeController = (
    state = {},
    action
  ) => {
    switch (action.type) {
      case "STORE_CONTROLLER":
        return action.payload
      default:
        return state
    }
  }
  
  export default storeController
  