import React from "react"
import { connect, useSelector } from "react-redux"

import { SnipcartWrapper } from "../elements"

import { SnipcartCard } from "../components"
import { getSnipcarts } from "../state/actions"
import { v4 as uuidv4 } from "uuid"

const Snipcart = ({ locale, dispatch, isDark, location }) => {
  const optimist = useSelector(state => state.optimistSnipcart)
  // console.log('optimist: ',optimist)
  let store = []
  if (!optimist.items) {
    dispatch(getSnipcarts())
    return (
      <SnipcartWrapper>
        <h1>Waiting for Shopping Cart...</h1>
      </SnipcartWrapper>
    )
  } else {
    const snips = Object.entries({ ...optimist }.items)
    if(snips.filter(value=>value[1].url===location).length>0){
      store = snips.filter(value=>value[1].url===location).map(value=>value[1])
      console.log('store: ',store[0].id)
    }else{
      return null
    }
  }


  return (
    <SnipcartWrapper isDark={isDark}>
      {store.map(value => {
        return (
          <div key={uuidv4()}>
            <SnipcartCard
              key={uuidv4()}
              isDark={isDark}
              item={value}
              locale={locale}
              customer={true}
            />
          </div>
        )
      })}
    </SnipcartWrapper>
  )
}
const mapStateToProps = state => ({ localeString: state.local })
export default connect(state => mapStateToProps)(Snipcart)
