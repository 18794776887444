import React from "react"
import { LogInWrapper } from "../elements"

export const ResetSuccess = ({ isDark, locale, location }) => {
  let lingua = require("../utils/lingua")(locale)("Auth")

  return (
    <div className="ResetPassword">
      <LogInWrapper>
        <div className={"gotoReset"}>
            <h1>{lingua.passwordResetMessage}</h1>
        </div>
      </LogInWrapper>
    </div>
  )
}
