 import React from 'react'
import {MessengerWrapper} from '../elements'
import {useSelector} from 'react-redux'
export const Messenger = ({isDark}) => {
  let request = useSelector(state => state.authFailure)
  if(request.visible){
    return (
      <MessengerWrapper isDark={isDark}>
        <p>{request.message}</p>
      </MessengerWrapper>
    )
  }else{
    return null
  }
}
