import styled from "styled-components"
export const ContainerWrapper = styled.div`
z-index: 9;
// z-index: 100;
display: grid;
position: relative;
grid-template-rows: 1fr auto auto;
grid-template-columns: repeat(6, 1fr);
// width: 52vw;
width: calc(100vw - calc(2 * ${props => props.theme.navHeight.sideBar}));
// left: 14vw;
left: ${props => props.theme.navHeight.sideBar};

grid-row-gap: 0;
min-height: calc(100vh - ${props => props.theme.navHeight.singleLine});
background: ${props =>
  props.theme.isDark ? props.theme.colors.dark2 : props.theme.colors.light2};
color: ${props =>
  props.theme.isDark ? props.theme.colors.light2 : props.theme.colors.dark2};
      
@media ${props => props.theme.breakpoints.tablet} {
  min-width: 100vw;
  left: 0;
  grid-template-columns: 2rem repeat(6, 1fr) 2rem;
  gap-gap: 0;
}

@media ${props => props.theme.breakpoints.mobile} {
  grid-template-columns: 1rem repeat(6, 1fr) 1rem; 
}
`