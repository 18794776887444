import React from "react"
import { RightSideBarItemWrapper } from "../elements"
import { connect, useSelector } from "react-redux"
import { 
  authRouter
} from "../state/actions"
export const SideBarRightItem = ({ dispatch, isDark, locale, location, title, action }) => {
    const dashboard = evt => {
        dispatch(authRouter(action))
    }
    return(
        <RightSideBarItemWrapper>
              <div className={"item"} onClick={dashboard}>
                <h1>{title}</h1>
              </div>

        </RightSideBarItemWrapper>
    )
}


const mapStateToProps = state => ({ dashboard: state.dashboard })
export default connect(state => mapStateToProps)(SideBarRightItem)

