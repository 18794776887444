import React from "react"
import { useSelector } from "react-redux"
import { DynamicImageWrapper } from "../elements"

export const SnipcartImage = ({ id, file, fileName, isDark, customer }) => {
  const locale = useSelector(state => state.localeString)
  const lingua = require("../utils/lingua")(locale)("StoreFitter")
  // console.log('file: ',fileName)
  const optimisticImagesStore = useSelector(state => state.snipcartImage)
  let optimisticImage = { ...optimisticImagesStore[id] }
  let imageFile = null
  let label
  if (fileName) {
    label = `${lingua.imageFileName}: ${fileName.slice(fileName.indexOf(id) + id.length)}`
  }
  if (optimisticImage) {
    imageFile = optimisticImage.file
  } else {
    imageFile = file
  }
  if (customer) {
    return (
      <DynamicImageWrapper>
        <img src={imageFile} className={"imageElement"} alt={"dynamic"} />
      </DynamicImageWrapper>
    )
  } else if (!imageFile) {
    return null
  } else {
    return (
      <DynamicImageWrapper>
        <img src={imageFile} className={"imageElement"} alt={"dynamic"} />
        <div className={"imageLabel"}>
          <h4>{label}</h4>
        </div>
      </DynamicImageWrapper>
    )
  }
}
