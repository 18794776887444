const unAuthRouter = (
  state=null,
  action
) => {
  switch (action.type) {
    case "UN_AUTH_ROUTER":
      return action.payload
    default:
      return state
  }
}

export default unAuthRouter
