/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createProduct = /* GraphQL */ `
  mutation CreateProduct(
    $input: CreateProductInput!
    $condition: ModelProductConditionInput
  ) {
    createProduct(input: $input, condition: $condition) {
      id
      pictureName
      pictureOwner
      salesPitch
      sasPOS
      productTitle
      file {
        bucket
        region
        key
      }
      locale
      sort
      price
      tax
      sku
      POS
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateProduct = /* GraphQL */ `
  mutation UpdateProduct(
    $input: UpdateProductInput!
    $condition: ModelProductConditionInput
  ) {
    updateProduct(input: $input, condition: $condition) {
      id
      pictureName
      pictureOwner
      salesPitch
      sasPOS
      productTitle
      file {
        bucket
        region
        key
      }
      locale
      sort
      price
      tax
      sku
      POS
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteProduct = /* GraphQL */ `
  mutation DeleteProduct(
    $input: DeleteProductInput!
    $condition: ModelProductConditionInput
  ) {
    deleteProduct(input: $input, condition: $condition) {
      id
      pictureName
      pictureOwner
      salesPitch
      sasPOS
      productTitle
      file {
        bucket
        region
        key
      }
      locale
      sort
      price
      tax
      sku
      POS
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createSnipcart = /* GraphQL */ `
  mutation CreateSnipcart(
    $input: CreateSnipcartInput!
    $condition: ModelSnipcartConditionInput
  ) {
    createSnipcart(input: $input, condition: $condition) {
      id
      name
      price
      description
      url
      file {
        bucket
        region
        key
      }
      pictureName
      taxes
      disabled
      locale
      sort
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateSnipcart = /* GraphQL */ `
  mutation UpdateSnipcart(
    $input: UpdateSnipcartInput!
    $condition: ModelSnipcartConditionInput
  ) {
    updateSnipcart(input: $input, condition: $condition) {
      id
      name
      price
      description
      url
      file {
        bucket
        region
        key
      }
      pictureName
      taxes
      disabled
      locale
      sort
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteSnipcart = /* GraphQL */ `
  mutation DeleteSnipcart(
    $input: DeleteSnipcartInput!
    $condition: ModelSnipcartConditionInput
  ) {
    deleteSnipcart(input: $input, condition: $condition) {
      id
      name
      price
      description
      url
      file {
        bucket
        region
        key
      }
      pictureName
      taxes
      disabled
      locale
      sort
      createdAt
      updatedAt
      owner
    }
  }
`;
