import React, { Component, createRef } from "react"
import { ModalWrapper } from "../elements"
import { connect } from "react-redux"
import { modalController } from "../state/actions"

class ModalOK extends Component {
  parentRefOk = createRef()
  componentDidUpdate(){
    this.parentRefOk.current.focus()
  }
  render() {
    let displayModal = false
    let modalState = { ...this.props.modalStateRedux }
    // modalState.type = "ok"
    if (modalState.type === "ok") {
      displayModal = true

      // this.parentRefOk.dispatchEvent(new KeyboardEvent('keypress', {
      //   key: 'Enter',
      // }));
      // const event = new KeyboardEvent('keypress', {
      //   key: 'enter',
      // });
    }

    const OKClick = evt => {
      modalReturn(true)
    }

    const OKKey = evt => {
      switch (evt.key) {
        case "Tab":
          evt.preventDefault()
          this.parentRefOk.current.focus()
          break
        case "Escape":
          modalReturn(true)
          break
        case "Enter":
          modalReturn(true)
          break
          default:
            console.log('')

      }
    }

    const modalReturn = state => {
      let returnState = { ...modalState }
      returnState.action = state
      returnState.type = undefined
      this.props.modalController(returnState)
    }

    return (
      <ModalWrapper displayModal={displayModal} isDark={this.props.isDark}>
        <div className={"modal-main"}>
          <div className={"title"}>
            <h1>{modalState.title}</h1>
          </div>
          <div className={"content"}>
            <p>{modalState.content}</p>
          </div>
          <div>
            <div className={"ok"}>
              <div
                className={"form-button"}
                onClick={OKClick}
                onKeyDown={OKKey}
                role="button"
                tabIndex="0"
                ref={this.parentRefOk}
              >
                <h3>OK</h3>
              </div>
            </div>
          </div>
        </div>
      </ModalWrapper>
    )
  }
}
const mapStateToProps = state => {
  return {
    modalStateRedux: state.modalController,
  }
}

const mapDispatchToProps = () => {
  return {
    modalController,
  }
}

export default connect(mapStateToProps, mapDispatchToProps(), null, {
  forwardRef: true,
})(ModalOK)
