import React, { Component, createRef } from "react"
import { ModalWrapper } from "../elements"
import { connect } from "react-redux"
import { 
  modalController, 
  deleteStoreItem,
  deleteSnipcartItem,
} from "../state/actions"

class ModalYesNo extends Component {
  parentRefOk = createRef()
  parentRefCancel = createRef()
  componentDidUpdate() {
    this.parentRefCancel.current.focus()
  }

  render() {
    let displayModal = false
    let modalState = { ...this.props.modalStateRedux }
    if (modalState.type === "yesno") {
      displayModal = true
      this.parentRefOk.current.focus()
    }

    const OKClick = evt => {
      modalReturn(true)
      switch (modalState.action) {
        case "deleteSnipcartItem":
          this.props.deleteSnipcartItem(modalState.item, modalState.store)
          break
        default:
          this.props.deleteStoreItem(modalState.item, modalState.store)
      }
    }

    const OKKey = evt => {
      switch (evt.key) {
        case "Tab":
          evt.preventDefault()
          this.parentRefCancel.current.focus()
          break
        case "Enter":
          modalReturn(true)
          this.props.deleteStoreItem(modalState.item, modalState.store)
          break
        case "Escape":
          modalReturn(false)
          break
        default:
          console.log("")
      }
    }

    const CancelClick = evt => {
      modalReturn(false)
    }

    const CancelKey = evt => {
      switch (evt.key) {
        case "Tab":
          evt.preventDefault()
          this.parentRefOk.current.focus()
          break
        case "Escape":
          modalReturn(false)
          break
        case "Enter":
          modalReturn(false)
          break
        default:
          console.log("")
      }
    }

    const modalReturn = state => {
      let returnState = { ...modalState }
      returnState.action = state
      returnState.type = undefined
      this.props.modalController(returnState)
    }

    return (
      <ModalWrapper displayModal={displayModal} isDark={this.props.isDark}>
        <div className={"modal-main"}>
          <div className={"title"}>
            <h1>{modalState.title}</h1>
          </div>
          <div className={"content"}>
            <p>{modalState.content}</p>
          </div>
          <div className={"buttons"}>
            <div className={"ok"}>
              <div
                className={"form-button"}
                onClick={OKClick}
                onKeyDown={OKKey}
                role="button"
                tabIndex="0"
                ref={this.parentRefOk}
              >
                <h3>OK</h3>
              </div>
            </div>
            <div className={"cancel"}>
              <div
                className={"form-button"}
                onClick={CancelClick}
                onKeyDown={CancelKey}
                role="button"
                tabIndex="0"
                ref={this.parentRefCancel}
              >
                <h3>CANCEL</h3>
              </div>
            </div>
          </div>
        </div>
      </ModalWrapper>
    )
  }
}

const mapStateToProps = state => {
  return {
    modalStateRedux: state.modalController,
  }
}

const mapDispatchToProps = () => {
  return {
    modalController,
    deleteStoreItem,
    deleteSnipcartItem,
  }
}

export default connect(mapStateToProps, mapDispatchToProps(), null, {
  forwardRef: true,
})(ModalYesNo)
