import styled from "styled-components"
export const LogOutWrapper = styled.div`
  font-size: 1rem;
  cursor: pointer;
  color: ${props =>
    props.isDark ? props.theme.colors.dark2 : props.theme.colors.light2};
  background-color: ${props =>
    props.isDark ? props.theme.colors.light2 : props.theme.colors.dark2};
  padding-top: 1px;
  padding-bottom: 4px;
  padding-right: 3px;
  padding-left: 3px;
  :hover {
      filter: brightness(50%);
      cursor: pointer;
    }
    
  .lo {
  }
`
