import React, { useContext } from "react"

// import React from "react"
import { useStaticQuery, Link, graphql } from "gatsby"
import { useSelector } from "react-redux"
import { NavWrapper } from "../elements"
import {} from "../components"
import Img from "gatsby-image"
import { ThemeManagerContext } from "gatsby-styled-components-dark-mode"

export const Nav = ({ location, url }) => {
  const data = useStaticQuery(graphql`
    query {
      light: imageSharp(fluid: { originalName: { eq: "gdb.png" } }) {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
      dark: imageSharp(fluid: { originalName: { eq: "gdw.png" } }) {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
      site {
        siteMetadata {
          bannerText
        }
      }
    }
  `)
  const locale = useSelector(state => state.localeString)
  let gdb = data.light.fluid
  let gdw = data.dark.fluid
  let nav = data.site.siteMetadata.bannerText
  let midLink = locale === "en" ? `${url}/en` : `${url}/ja`
  const themeContext = useContext(ThemeManagerContext)
  return (
    <NavWrapper isDark={themeContext.isDark}>
      <div className={"lhs"}>
        <div className={"burger"}></div>
      </div>
      <div className={"tst"}>
        <div className={"mid"}>
          <Link to={midLink}>
            <div className={"mid-wrapper"}>
              <div className={"mid-image"}>
                <Img
                  fluid={themeContext.isDark ? gdw : gdb}
                  style={{ width: "23px" }}
                  alt={"Site Logo and link to home page."}
                />
              </div>
              <div className={"mid-text"}>
                {/* <div className={"wml-text"}></div> */}
                <div className={"wml-text"}>{nav}</div>
                {/* <div className={"wml-text"}></div> */}
              </div>
            </div>
          </Link>
        </div>
      </div>
    </NavWrapper>
  )
}

export default Nav
