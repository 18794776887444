const dynamicImageReducer = (state = {}, action) => {
  switch (action.type) {
    case "OPTIMISTIC_IMAGE":
      return action.payload
    default:
      return state
  }
}

export default dynamicImageReducer
