import React, { useEffect } from "react"
import { connect, useSelector, useDispatch } from "react-redux"

import { Auth, Hub } from "aws-amplify"

import {
  Spinner,
  LogIn,
  StoreFitter,
  Reset,
  Create,
  SnipcartDashboard,
  ChatDashboard,
} from "../components"

import { AuthWrapper } from "../elements"
import { loginStatus, authRouter } from "../state/actions"
import { v4 as uuidv4 } from "uuid"


export const Auth2 = ({
  isDark,
  locale,
  // dispatch,
  location,
  // unAuthRouter,
}) => {
  const router = useSelector(state => state.authRouter)
  const dispatch = useDispatch()

  useEffect(() => {
    Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
          dispatch(loginStatus({ logger: true }))
          // dispatch(authRouter("WELCOME"))
          dispatch(authRouter("SNIPCART"))
          break
        case "signOut":
          dispatch(loginStatus({ logger: false }))
          dispatch(authRouter("LOGIN"))
          break
        case "signIn_failure":
          dispatch(loginStatus({ logger: false }))
          dispatch(authRouter("LOGIN"))
          break
      }
    })
    getUser().then(userData => {
      if (userData) {
        dispatch(loginStatus({ logger: true }))
        // dispatch(authRouter("WELCOME"))
        dispatch(authRouter("SNIPCART"))
      } else {
        dispatch(loginStatus({ logger: false }))
        dispatch(authRouter("LOGIN"))
      }
    })
  }, [])
  function getUser() {
    return Auth.currentAuthenticatedUser()
      .then(userData => userData)
      .catch(() => console.log("Not signed in"))
  }
  const pos1 = header => {
    return (
      <div>
        <h1>{header}</h1>
      </div>
    )
  }
  const spinner = () => {
    return <Spinner isDark={isDark} />
  }

  const login = () => {
    return (
      <div className={"auth"}>
        <LogIn isDark={isDark} locale={locale} />
      </div>
    )
  }
  const storeFitter = () => {
    return (
      <div className={"auth"}>
        <StoreFitter locale={locale} isDark={isDark} />
      </div>
    )
  }
  const welcome = () => {
    return (
      <div>
        <h1>Welcome</h1>
      </div>
    )
  }
  const chat = () => {
    return (
      <div className={"auth"}>
        <ChatDashboard locale={locale} isDark={isDark} />
      </div>
    )
  }
  const snipcart = () => {
    return (
      <div className={"auth"}>
        <SnipcartDashboard locale={locale} isDark={isDark} />
      </div>
    )
  }
  const reset = () => {
    return <Reset isDark={isDark} locale={locale} location={location} />
  }
  const create = () => {
    return <Create isDark={isDark} locale={locale} location={location} />
  }
  switch (router) {
    case "WELCOME":
      return (
        <AuthWrapper>
          <div className={"pos-one"}></div>
          <div className={"pos-two"}>{welcome()}</div>
          <div className={"pos-three"}></div>
        </AuthWrapper>
      )
    case "CREATE_ACCOUNT":
      return (
        <AuthWrapper>
          <div className={"pos-one"}>{pos1("Create Account")}</div>
          <div className={"pos-two"}>{create()}</div>
          <div className={"pos-three"}>{spinner()}</div>
        </AuthWrapper>
      )
    case "CRIT":
      return (
        <AuthWrapper>
          <div className={"pos-one"}>{pos1("TODO: Auth Controller")}</div>
          <div className={"pos-two"}>{"TODO: Crit Controller"}</div>
          <div className={"pos-three"}>{spinner()}</div>
        </AuthWrapper>
      )
    case "STORE_FITTER":
      return (
        <AuthWrapper>
          <div className={"pos-one"}></div>
          <div className={"pos-two"}>{storeFitter()}</div>
          <div className={"pos-three"}>{spinner()}</div>
        </AuthWrapper>
      )
    case "CHAT":
      return (
        <AuthWrapper>
          <div className={"pos-one"}></div>
          <div className={"pos-two"}>{chat()}</div>
          <div className={"pos-three"}>{spinner()}</div>
        </AuthWrapper>
      )
    case "SNIPCART":
      return (
        <AuthWrapper>
          <div className={"pos-one"}></div>
          <div className={"pos-two"}>{snipcart()}</div>
          <div className={"pos-three"}>{spinner()}</div>
        </AuthWrapper>
      )
    case "RESET_ACCOUNT":
      return (
        <AuthWrapper>
          <div className={"pos-one"}>{pos1("Reset Account")}</div>
          <div className={"pos-two"}>{reset()}</div>
          <div className={"pos-three"}>{spinner()}</div>
        </AuthWrapper>
      )
    default:
      return (
        <AuthWrapper>
          <div className={"pos-one"}></div>
          {/* <div className={"pos-two"}>{snipcart()}</div> */}
          <div className={"pos-two"}>{login()}</div>
          <div className={"pos-three"}>{spinner()}</div>
        </AuthWrapper>
      )
  }
}
// const mapStateToProps = state => ({ dashboard: state.dashboard })
// export default connect(state => mapStateToProps)(Auth2)
