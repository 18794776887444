import React, { useContext } from "react"
import { HRWithTitleWrapper } from "../elements"
import { ThemeManagerContext } from "gatsby-styled-components-dark-mode"

export const SnipcartHR = ({ lingua }) => {
  const isDark = {...useContext(ThemeManagerContext)}.isDark
  return (
    <HRWithTitleWrapper isDark={isDark}>
      <p> {!lingua ? "" : lingua}&nbsp;</p>
      <hr />
    </HRWithTitleWrapper>
  )
}
export default SnipcartHR
