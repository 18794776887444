import React from "react"
import { useSelector } from "react-redux"
import { SnipcartCardWrapper } from "../elements"
import { SnipcartImage, SnipcartButton } from "../components"
import awsmobile from "../aws-exports"

export const SnipcartCard = ({ locale, dispatch, item, isDark, customer }) => {
  const id = item.id
  const redux = useSelector(state => state.optimistSnipcart)
  let optimist = { ...redux }.items
  let cardToDisplay = optimist.filter(card => card.id === id)[0]
  let edit = true
  let show = false
  let controller = useSelector(state => state.storeController)
  const imageURL = `https://${awsmobile.aws_user_files_s3_bucket}.s3.${
    awsmobile.aws_user_files_s3_bucket_region
  }.amazonaws.com/public/${item.file.key}`
  if (customer) {
    show = true
    edit = true
  } else {
    Object.keys(controller)
      .filter(key => key === id)
      .forEach(id => {
        edit = !controller[id].edit
        show = controller[id].show
      })
  }

  // const buyClick = evt => {
  //   window.open(cardToDisplay.sasPOS, "_blank")
  // }
  // const buyKey = evt => {
  //   if (evt.key === "Enter") {
  //     window.open(cardToDisplay.sasPOS, "_blank")
  //   }
  // }

  const totalFloat = (cardToDisplay.price / 100).toFixed(2)
  const unitPrice = Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "gbp",
  }).format(totalFloat)

  if (cardToDisplay) {
    return (
      <SnipcartCardWrapper isDark={isDark} visible={edit && show}>
        <div className={"cardTitle"}>
          <h1>{cardToDisplay.name}</h1>
        </div>
        <div className={"cardImage"}>
          <SnipcartImage id={id} customer={customer} fileName={item.file.key}/>
        </div>
        <div className={"cardPitch"}>
          <p>{cardToDisplay.description}</p>
        </div>
        <div className={"unitPrice"}> 
          <p>{unitPrice}</p>
        </div>
        <div className={"cardBuy"}>
          <SnipcartButton
            itemID={cardToDisplay.id}
            itemPrice={cardToDisplay.price/100}
            itemURL={`/`+cardToDisplay.url}
            itemDescription={cardToDisplay.description}
            itemImage={imageURL}
            itemName={cardToDisplay.name}
            itemTax={cardToDisplay.tax}
            itemDisabled={cardToDisplay.disabled}
            isDark={isDark}
            locale={locale}
          />
        </div>
      </SnipcartCardWrapper>
    )
  } else {
    return null
  }
}
export default SnipcartCard
