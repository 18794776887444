import React, { useContext } from "react"
import { ContainerWrapper } from "../elements"
import { connect, useSelector } from "react-redux"
import { useStaticQuery, graphql } from "gatsby"

import {
  Header,
  // TextMenu,
  Main,
  Footer,
  SideBarLeft,
  SideBarRight,
  Top,
  // Modal,
  // Modal2,
  ModalOK,
  ModalYesNo,
  Chat,
  // Hamburger2,
  Hamburger3,
} from "../components"
import { ThemeManagerContext } from "gatsby-styled-components-dark-mode"

import { Amplify } from "aws-amplify"
import awsmobile from "../aws-exports"

const Container = ({ children, location, consent, dispatch }) => {
  Amplify.configure(awsmobile)

  const textLocale = useSelector(state => state.localeString) === "en" ? "en" : "ja"
  const locale = textLocale
  const themeContext = useContext(ThemeManagerContext)
  const isDark = themeContext.isDark
  

  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          url
        }
      }
      light: imageSharp(fluid: { originalName: { eq: "gdb.png" } }) {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
      dark: imageSharp(fluid: { originalName: { eq: "gdw.png" } }) {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  `)
  const gdb = data.light.fluid
  const gdw = data.dark.fluid
  const url = data.site.siteMetadata.url



  return (
    <Top>
      <Hamburger3
        url={url}
        locale={locale}
        location={location}
        isDark={themeContext.isDark}
        gdb={gdb}
        gdw={gdw}
      />
      <ModalYesNo 
        isDark={isDark} 
        locale={locale} 
      />
      <ModalOK isDark={themeContext.isDark} />
      <Header location={location} url={url} />
      <SideBarLeft 
        isDark={themeContext.isDark} 
        locale={textLocale}
        url={url}
      />
      <ContainerWrapper>

        <Main>{children}</Main>


        <Footer location={location} locale={locale}/>

      </ContainerWrapper>
      <SideBarRight
        isDark={themeContext.isDark}
        locale={textLocale}
        location={location}
        url={url}
      />
    </Top>
  )
}

const mapStateToProps = state => ({ localeString: state.consent })
export default connect(state => mapStateToProps)(Container)