const itemAddedSuccessReducer = (state = false, action) => {
  switch (action.type) {
    case "ITEM_ADDED":
      return null
    default:
      return action
  }
}

export default itemAddedSuccessReducer
