import styled from "styled-components"
export const MessengerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: ${props => props.isDark ? props.theme.colors.light2 : props.theme.colors.dark2};
  h1 {
    font-size: 1rem;
  }
`
