import styled from "styled-components"
export const SideBarWrapper = styled.div`
// overflow: auto;
// background-color: pink;

background-color: ${props =>
  props.theme.isDark ? props.theme.colors.dark2 : props.theme.colors.light2};
background: ${props =>
  props.theme.isDark ? props.theme.colors.dark2 : props.theme.colors.light2};
color: ${props =>
  props.theme.isDark ? props.theme.colors.light2 : props.theme.colors.dark2};


position: fixed;
left: 0;
// right: ${props => (props.right ? "0" : null)};
top: ${props => props.theme.navHeight.singleLine};

display: grid;
// width: 14vw;

// max-width: ${props => props.theme.navHeight.sideBar};
width: ${props => props.theme.navHeight.sideBar};
min-height: calc(100vh - ${props => props.theme.navHeight.singleLine});
// padding-right: ${props =>
  props.right ? props.theme.navHeight.sideBar : null};
text-align: center;
grid-column: 2;
grid-template-rows:  calc( 2 * ${props =>
  props.theme.navHeight.singleLine}) auto;

.menu{
  display: grid;
  grid-template-rows: 1fr 1fr;
  // border-bottom: 1px solid ${props =>
    props.isDark ? props.theme.colors.light2 : props.theme.colors.dark2};  
}

.menuItem{
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
}

.subMenu{
  top: 0;
  z-index: 102;
  padding: 6rem 0;
    // grid-row: 2;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;  
    a{
      text-decoration: none;
    }
  }
h1{
  color: ${props =>
    props.isDark ? props.theme.colors.light2 : props.theme.colors.dark2};
  font-size: 3rem;
}
h2{
  color: ${props =>
    props.isDark ? props.theme.colors.light2 : props.theme.colors.dark2};
  font-size: 1.5rem;
}
h3{
  color: ${props =>
    props.isDark ? props.theme.colors.light2 : props.theme.colors.dark2};
  font-size: 0.8rem;
}

a{
  text-decoration: none; 
}
@media ${props => props.theme.breakpoints.tablet} {
  display: none;
}

@media ${props => props.theme.breakpoints.mobile} {
  display: none;
}
`
