const chat = (state = {}, action) => {
    switch (action.type) {
      case "CHAT":
        // if (action.payload === state) {
        //   return {}
        // } else {
          return action.payload
        // }
      default:
        return state
    }
  }
  
  export default chat
  