import React from "react"
import { connect } from "react-redux"
import {} from '../state/actions'
import {
  SnipcartButtonWrapperLight,
  SnipcartButtonWrapperDark,
  SnipcartButtonWrapper,
} from "../elements"

export const SnipcartButton = ({
  itemID,
  itemPrice,
  itemURL,
  itemDescription,
  itemImage,
  itemName,
  itemTax,
  itemDisabled,
  isDark,
  locale
}) => {
  const url = `https://b374ppgrad.execute-api.us-east-1.amazonaws.com/Test/${locale}${itemURL}`
  // const url = `https://fleet.psdmedia.co.uk/${locale}${itemURL}/Product.json`
  
  const sButton = () => {
    if (isDark) {
      return (
        <SnipcartButtonWrapperDark className={"snipcart-overwrite"}>
          <button
            className={"snipcart-add-item"}
            data-item-id={itemID}
            data-item-price={itemPrice}
            data-item-url={url}
            data-item-description={itemDescription}
            data-item-image={itemImage}
            data-item-name={itemName}
            data-item-quantity="1"
            data-item-taxes={itemTax}
            disabled={itemDisabled}
          >
            BUY
          </button>
        </SnipcartButtonWrapperDark>
      )
    } else {
      return (
        <SnipcartButtonWrapperLight className={"snipcart-overwrite"}>
          <button
            className={"snipcart-add-item"}
            data-item-id={itemID}
            data-item-price={itemPrice}
            data-item-url={url}
            data-item-description={itemDescription}
            data-item-image={itemImage}
            data-item-name={itemName}
            data-item-quantity="1"
            data-item-taxes={itemTax}
            disabled={itemDisabled}
          >
            Add to Cart
          </button>
        </SnipcartButtonWrapperLight>
      )
    }
  }
  return <SnipcartButtonWrapper>{sButton()}</SnipcartButtonWrapper>
}
const mapStateToProps = state => ({ localeString: state.local })
export default connect(state => mapStateToProps)(SnipcartButton)

