const snipcartCreateReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case "SNIPCART_CREATE":
      return action.payload
    default:
      return state
  }
}
export default snipcartCreateReducer
