import  React from "react"

import { ThemeProvider } from "styled-components"
// import { ThemeManagerContext } from "gatsby-styled-components-dark-mode"

import { MDXProvider } from "@mdx-js/react"
import { preToCodeBlock } from "mdx-utils"
import { theme, GlobalStyles } from "./src/themes"
import { Table, Code } from "./src/components"
import "./language-tabs.css"

import { Provider } from "react-redux"

import createStore from "./src/state/createStore"

const components = {
  table: Table,
  pre: preProps => {
    const props = preToCodeBlock(preProps)
    if (props) {
      return <Code {...props} />
    }
    return <pre {...preProps} />
  },
  wrapper: ({ children }) => <>{children}</>,
}

export const wrapRootElement = (props) => {
  const store = createStore()

  return (
    <Provider store={store}>
      <MDXProvider components={components}>
        <ThemeProvider theme={theme}>
          <GlobalStyles theme={props.theme} />
          {props.element}
        </ThemeProvider>
      </MDXProvider>
    </Provider>
  )
}
