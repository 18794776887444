import React from "react"
import { connect, useSelector } from "react-redux"

import { SnipcartViewControllerWrapper } from "../elements"

import { 
  SnipcartEdit,
  SnipcartCard,
  SnipcartFormCreate,
} from "../components"
import { storeController } from "../state/actions"

const SnipcartViewController = ({ locale, dispatch, isDark, item, store }) => {
  let id = item.id
  let show = false
  let reduxController = useSelector(state => state.storeController)


  const toggleShow = evt => {
    if (evt.nativeEvent.keyCode === 13 || evt.nativeEvent.type === "click") {
      let controller = { ...reduxController }
      controller[id] = controller[id] || {}
      controller[id].show = controller[id].show || false
      controller[id].show = !controller[id].show
      dispatch(storeController(controller))
    }
  }
  return (
    <SnipcartViewControllerWrapper isDark={isDark} show={show}>
      <div
        onClick={toggleShow}
        onKeyPress={toggleShow}
        role="button"
        tabIndex="0"
      >
        <div className={"form-button"}>
          <h1>{item.name}</h1>
        </div>
      </div>
      <SnipcartEdit isDark={isDark} item={item} store={store} />
    </SnipcartViewControllerWrapper>
  )
}
const mapStateToProps = state => ({ localeString: state.local })
export default connect(state => mapStateToProps)(SnipcartViewController)
