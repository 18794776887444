const snipcartSitesReducer = (
    state = {
      items: [],
    },
    action
  ) => {
    switch (action.type) {
      case "SNIPCART_SITES":
        return action.payload
      default:
        return state
    }
  }
  
  export default snipcartSitesReducer