import React, { useContext } from "react"

import {
  FooterWrapper,
  FooterSocialWrapper,
  FooterSocialIcons,
  P,
} from "../elements"
import { 
  DarkMode, 
  LogOut, 
  // Chat 
} from "../components"

import { connect } from "react-redux"
import { ThemeManagerContext } from "gatsby-styled-components-dark-mode"

const Footer = () => {
  const themeContext = useContext(ThemeManagerContext)
  return (
    <FooterWrapper>
      <FooterSocialWrapper isDark={themeContext.isDark}>
        <div className={"gridTemplate"}>
          <FooterSocialIcons/>
          <div className={"settings"}>
            <DarkMode />
            <LogOut isDark={themeContext.isDark} />
          </div>
          <div className={"copyright"}>
            <P size="xSmall" color="dark3">
              © 2021 PSDMedia.co.uk
            </P>
            <P size="xSmall" color="dark3">
              All rights reserved
            </P>
          </div>
        </div>
      </FooterSocialWrapper>
    </FooterWrapper>
  )
}

const mapStateToProps = state => ({ dashboard: state.dashboard })
export default connect(state => mapStateToProps)(Footer)
