module.exports = {
  metadata: {
    contributors: "D Fletcher",
  },
  components: [
    {
      name: `Snipcart`,
      text: {
        snipcart: `Snipcart`,
        price: `Price`,
        productName: `Snipcart Name`,
        productCopy: `Snipcart Copy`,
        productImage: `Snipcart Image`,
        imageFileName: `File Name`,
        findImageFile: `Find Image File`,
        uploadProduct: `Upload`,
        updateProduct: `Update`,
        pageDrop: `Snipcart Page`,
        dropdownMessage1: `Choose a Page`,
        dropdownMessage2: `Available Pages:`,
        order: `Running Order`,
      }
    },
    {
      name: `StoreFitter`,
      text: {
        storeFitter: `StoreFitter`,
        productName: `Product Name`,
        productCopy: `Product Copy`,
        productImage: `Product Image`,
        imageFileName: `File Name`,
        findImageFile: `Find Image File`,
        uploadProduct: `Upload`,
        updateProduct: `Update`,
        posDrop: `Point of Sale`,
        posStripe: `Stripe ID`,
        posURL: `URL or ID`,
      }
    },
    {
      name: `Auth`,
      text: {
        username: `Username`,
        password: `Password`,
        resetPassword: `Reset Password`,
        login: `Login`,
        sendConfirmation: `Send Confirmation`,
        confirmationCode: `Confirmation Code`,
        confirmationMessage: `Please check your email for the confirmation code.`,
        newPassword: `New Password`,
        confirmPassword: `Confirm Password`,
        confirm: `Confirm`,
        passwordErrorMessage: `Password must be six or more characters in length; contain one number and have at least one upper-case letter.`,
        passwordResetMessage: `Your password has been reset. You can now login with your new password.`,
        formValidationError: `Form does not validate. Please make sure your passwords match, and you have entered the verification code from your email.`,
      },
    },
    {
      name: `Footer`,
      text: {
        login: `Login`,
        dashboard: `Dashboard`,
        chat: `Chat`
      },
    },
    {
      name: `Dashboard`,
      text: {
        title: `Dashboard`,
        usernamePlaceholderText: `Username`,
        passwordPlaceholderText: `Password`,
        loginButtonText: `Login`,
        passwordReset: `Reset Password?`
      },
    },

    {
      name: `Home`,
      text: {
        title: `psdmedia.co.uk`,
        artistStatement: `A short, single paragraph,  artists statement. Lorem ipsum, dolor sit amet consectetur adipisicing elit. Qui dolorum debitis voluptates, aliquam consequuntur incidunt, aspernatur corrupti deleniti saepe rem quidem. Ratione quidem possimus molestias et rerum illum rem tempore!`,
        image: `seoImage.png`,
        HRText: `Quick Jump Tiles`,
      },
    },
    {
      name: `Dashboard`,
      text: {
        title: `Dashboard`,
      },
    },
    {
      name: `Gallery`,
      text: {
        heading: `Gallery Space`,
        statement: `This content needs to find an angle so that your audience will want to explore your work.`,
        image: `seoImage.png`,
        nextPage: `Next Page`,
        previousPage: `Previous Page`,
      },
    },
    {
      name: `ContactAndSales`,
      text: {
        email: `sales@psdmedia.co.uk`,
        title: `Contact and Sales`,
        statement: `Some content that will entice your audience to part with their hard earned cash.`,
        image: `seoImage.png`,
        test: [
          {
            productImage: `product_01.png`,
            price: `$1hk`,
            productTitle: `Product Title 1`,
            depop: `https://depop.com`,
            paypal: `https://paypal.com`,
            locale: `en`,
            pitch: `Lorem ipsum, dolor sit amet consectetur adipisicing elit. In, amet ut. Nulla, eligendi totam. Provident natus, a totam, exercitationem illum officiis, illo eveniet eligendi dolore iste doloribus impedit placeat delectus!`,
          },
          {
            productImage: `product_02.png`,
            price: `$10hk`,
            productTitle: `Product Title 2`,
            depop: `https://depop.com`,
            paypal: `https://paypal.com`,
            locale: `en`,
            pitch: `Lorem ipsum, dolor sit amet consectetur adipisicing elit. In, amet ut. Nulla, eligendi totam. Provident natus, a totam, exercitationem illum officiis, illo eveniet eligendi dolore iste doloribus impedit placeat delectus!`,
          },
          {
            productImage: `product_03.png`,
            price: `$100hk`,
            productTitle: `Product Title 3`,
            depop: `https://depop.com`,
            paypal: `https://paypal.com`,
            locale: `en`,
            pitch: `Lorem ipsum, dolor sit amet consectetur adipisicing elit. In, amet ut. Nulla, eligendi totam. Provident natus, a totam, exercitationem illum officiis, illo eveniet eligendi dolore iste doloribus impedit placeat delectus!`,
          },
          {
            productImage: `product_04.png`,
            price: `$1000hk`,
            productTitle: `Product Title 4`,
            depop: `https://depop.com`,
            paypal: `https://paypal.com`,
            locale: `en`,
            pitch: `Lorem ipsum, dolor sit amet consectetur adipisicing elit. In, amet ut. Nulla, eligendi totam. Provident natus, a totam, exercitationem illum officiis, illo eveniet eligendi dolore iste doloribus impedit placeat delectus!`,
          },
          {
            productImage: `product_05.png`,
            price: `$10000hk`,
            productTitle: `Product Title 5`,
            depop: `https://depop.com`,
            paypal: `https://paypal.com`,
            locale: `en`,
            pitch: `Lorem ipsum, dolor sit amet consectetur adipisicing elit. In, amet ut. Nulla, eligendi totam. Provident natus, a totam, exercitationem illum officiis, illo eveniet eligendi dolore iste doloribus impedit placeat delectus!`,
          },
          {
            productImage: `product_06.png`,
            price: `$10hk`,
            productTitle: `Product Title 6`,
            depop: `https://depop.com`,
            paypal: `https://paypal.com`,
            locale: `en`,
            pitch: `Lorem ipsum, dolor sit amet consectetur adipisicing elit. In, amet ut. Nulla, eligendi totam. Provident natus, a totam, exercitationem illum officiis, illo eveniet eligendi dolore iste doloribus impedit placeat delectus!`,
          },
          {
            productImage: `product_07.png`,
            price: `$12hk`,
            productTitle: `Product Title 7`,
            depop: `https://depop.com`,
            paypal: `https://paypal.com`,
            locale: `en`,
            pitch: `Lorem ipsum, dolor sit amet consectetur adipisicing elit. In, amet ut. Nulla, eligendi totam. Provident natus, a totam, exercitationem illum officiis, illo eveniet eligendi dolore iste doloribus impedit placeat delectus!`,
          },
          {
            productImage: `product_08.png`,
            price: `$14hk`,
            productTitle: `Product Title 8`,
            depop: `https://depop.com`,
            paypal: `https://paypal.com`,
            locale: `en`,
            pitch: `Lorem ipsum, dolor sit amet consectetur adipisicing elit. In, amet ut. Nulla, eligendi totam. Provident natus, a totam, exercitationem illum officiis, illo eveniet eligendi dolore iste doloribus impedit placeat delectus!`,
          },
          {
            productImage: `product_09.png`,
            price: `$16hk`,
            productTitle: `Product Title 9`,
            depop: `https://depop.com`,
            paypal: `https://paypal.com`,
            locale: `en`,
            pitch: `Lorem ipsum, dolor sit amet consectetur adipisicing elit. In, amet ut. Nulla, eligendi totam. Provident natus, a totam, exercitationem illum officiis, illo eveniet eligendi dolore iste doloribus impedit placeat delectus!`,
          },
          {
            productImage: `product_10.png`,
            price: `$18hk`,
            productTitle: `Product Title 10`,
            depop: `https://depop.com`,
            paypal: `https://paypal.com`,
            locale: `en`,
            pitch: `Lorem ipsum, dolor sit amet consectetur adipisicing elit. In, amet ut. Nulla, eligendi totam. Provident natus, a totam, exercitationem illum officiis, illo eveniet eligendi dolore iste doloribus impedit placeat delectus!`,
          },
        ],
      },
    },
    {
      name: `404`,
      text: `Uh..Oh, what your looking for cannot be found.`,
    },
    {
      name: `Hamburger`,
      text: {
        title: `Home`,
        gallery: `Gallery`,
        contact: `Contact and Sales`,
        storeFitter: `StoreFitter`,
        snipcart: `Snipcart`,
        chat: `Chat`,
      },
    },
    {
      name: `NavbarTitle`,
      text: {
        row1: ``,
        row2: `psdmedia.co.uk`,
        row3: ``,
      },
    },
    {
      name: `Months`,
      text: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
    },
  ],
}
