import React from "react"
import { Hamburger2BodyWrapper } from "../elements"
import { Hamburger4Item } from "../components"
import { connect, useSelector } from "react-redux"
import { hamburger } from "../state/actions"
import { v4 as uuidv4 } from "uuid"
const Hamburger4Body = ({ location, isDark, dispatch }) => {
  const changeRedux = useSelector(state => state.hamburger)
  let state = {...changeRedux}.showMenu2
  const locale = useSelector(state => state.localeString)
  let burger = require("../utils/lingua")(locale)("Hamburger")
  // let homeLink = locale === "en" ? "../../en" : "../../ja"
  let localeLink = locale === "en" ? "en" : "ja"
  const bodyClick = evt => {
    dispatch(hamburger({showMenu2: !state}))
  }
  const bodyKey = evt => {
    console.log('body: ',evt)
    // dispatch(hamburger({showMenu: !state}))
  }
  return (
    <Hamburger2BodyWrapper state={state} isDark={isDark} >
        <div
          className={"navFadeWrapper"}
          onClick={bodyClick}
          onKeyDown={bodyKey}
          role="button"
          tabIndex="-1"
        >
          <Hamburger4Item
            id={uuidv4()}
            isDark={isDark}
            itemText={burger.snipcart}
            action={"SNIPCART"}
            gridRow={`2`}
            burger={burger}
            transition={`1s`}
            />
          <Hamburger4Item
            id={uuidv4()}
            isDark={isDark}
            itemText={burger.storeFitter}
            action={"STORE_FITTER"}
            gridRow={`3`}
            burger={burger}
            transition={`1.5s`}
            />
          <Hamburger4Item
            id={uuidv4()}
            isDark={isDark}
            itemText={burger.chat}
            action={"CHAT"}
            gridRow={`4`}
            transition={`2s`}
            burger={burger}
            />
        </div>
    </Hamburger2BodyWrapper>
  )
}
const mapStateToProps = state => ({ localeString: state.consent })
export default connect(state => mapStateToProps)(Hamburger4Body)
