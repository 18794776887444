import React from 'react'
import {SpinnerWrapper} from "../elements"
import { useSelector} from "react-redux"
export const Spinner = ({isDark}) => {
  let spinner = useSelector(state => state.spinner)
  // let test = true
  if (spinner.visible){
  // if (test){
    return (
      <SpinnerWrapper isDark={isDark}>
        <div className={"loader"}>{spinner.text}</div>
      </SpinnerWrapper>
    )
  } else {
    return null
  }
}