import styled from "styled-components"
export const ContactWrapper = styled.div`
  display: static;
  grid-template-rows: auto auto auto auto auto 2fr;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 3rem;
  grid-column-gap: ${props => props.theme.spacings.large};
  .carousel {
    grid-column: 1 / span 2;
    box-shadow: none;
    padding: 1rem 0;
  }
  h1 {
    text-align: center;
    grid-row: 3;
    color: inherit;
    padding: 2rem 0;
  }
  
  p {
    padding-bottom: 2rem;
    grid-row: 4;
    color: inherit;
    text-align: center;
  }

  .top {
    grid-row: 1;
  }

  .MDX {
    box-shadow: none;
    grid-column: 1;
    // grid-row: ;
    padding: 0 10rem;
  }
  .horizontalRule {
    display: none;
  }
  .tiles {
    display: grid;
    grid-row: 2;
    grid-column: 1 / span 2;
  }

  .titleStatement {
    grid-column: 2;
    grid-row: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
  @media ${props => props.theme.breakpoints.tablet} {
    grid-column: 2 / span 6;
    padding-top: 10rem;

    .MDX {
      padding: ${props => props.theme.spacings.large} 0;
    }

    .titleStatement {
      justify-content: space-around;
    }
    display: grid;
    grid-template-rows: auto auto 2fr;
    grid-row-gap: 2rem;
    .sideBar {
      display: none;
    }
  }

  @media ${props => props.theme.breakpoints.mobile} {
    padding-top: 1.4rem;
    display: grid;
    grid-template-columns: 1fr;
    grid-column: 1;
    grid-column-gap: 0;
    grid-row-gap: 1.4rem;
    overflow: hidden;
    grid-template-rows: auto auto auto auto 2fr;
    .top {
      // display: grid;
      grid-row: 1;
    }
    h1 {
      font-size: 1.8rem;
    }
    .titleStatement {
      display: grid;
      grid-row: 2;
      grid-column: 1;
    }
    .horizontalRule {
      display: grid;
    }
    .tiles {
      grid-row: 4;
      // grid-column: 1;
    }
    .MDX {
      // grid-column: 1;
      padding: 0.5rem;
      line-height: 1rem;
      blockquote {
        p {
          padding: 0;
          margin: 0;
        }
      }
    }
  }
`
