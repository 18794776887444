import styled from "styled-components"
export const HRWithTitleWrapper = styled.div`
  display: grid;
  grid-auto-columns: auto 1fr;
  h4 {
    grid-column: 1;
    font-weight: bold;
    color: ${props=> props.isDark ? props.theme.colors.light3 : props.theme.colors.dark2};
  }
  p {
    grid-column: 1;
    grid-row: 1;
    font-weight: bold;
    color: ${props=> props.isDark ? props.theme.colors.light3 : props.theme.colors.dark2};
  }
  hr {
    grid-column: 2;
    margin-top: 0.45rem;
    margin-bottom: 0.45rem;
    
    border-top: 0.15rem solid ${props=> props.isDark ? props.theme.colors.light3 : props.theme.colors.dark2};
    border-bottom: 0;
    border-radius: 0.15rem;
    
    
  }
  width: 100%;
  @media ${props => props.theme.breakpoints.tablet} {
  }
  
  @media ${props => props.theme.breakpoints.mobile} {
    grid-row: ${props => props.row};
    hr {
      grid-column: 2;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      border-top: 0.15rem solid ${props=> props.isDark ? props.theme.colors.light3 : props.theme.colors.dark2};
      border-bottom: 0;
      border-radius: 0.15rem; 
    }    
  }
`
