const dynamicImageReducer = (state = {}, action) => {
  switch (action.type) {
    case "DYNAMIC_IMAGE":
      // if (action.payload === state) {
      //   return {}
      // } else {
        return action.payload
      // }
    default:
      return state
  }
}

export default dynamicImageReducer
