import styled from "styled-components"
export const ChatBoxWrapper = styled.div`
  overflow: scroll;
  grid-column: 1 / span 2;
  grid-row: 2;
  .fleet {
    margin-bottom: 0.5rem;
    color: red;
  }
  .client {
    margin-bottom: 0.5rem;
    color: blue;
  }
  @media ${props => props.theme.breakpoints.tablet} {
    font-size: 0.7rem;
  }
`

export const ChatWrapper = styled.div`
  z-index: 1000;
  position: fixed;
  top: -${props => props.theme.navHeight.singleLine};
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: ${props => (props.displayModal ? `flex` : `none`)};
  justify-content: center;
  align-items: flex-start;
  align-content: center;

  .text-area-input {
    border: 0;
    box-shadow: 0 0 15px 4px rgba(0, 0, 0, 0.06);
    padding: 10px;
    border-radius: 0.5rem;
    width: 100%;
    height: 15rem;
    resize: none;
  }

  .modal-main {
    display: grid;
    grid-row-gap: 0.5rem;
    background: ${props =>
      props.isDark ? props.theme.colors.dark2 : props.theme.colors.light2};
    color: ${props =>
      props.isDark ? props.theme.colors.light2 : props.theme.colors.dark2};
    width: 40%;
    height: 40%;
    border-radius: 2rem;
    grid-template-rows: 5rem 3fr 0.5fr;
    grid-template-columns: 1fr 1fr;
    position: relative;
    top: 150px;
    padding: 1rem;
  }

  .send {
    grid-column: 1;
    grid-row: 3;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .close {
    grid-column: 2;
    grid-row: 3;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .form-button {
    appearance: none;
    -webkit-appearance: none;
    border-radius: 2rem;
    padding: 10px;
    border: 2px solid
      ${props =>
        props.isDark ? props.theme.colors.light2 : props.theme.colors.dark2};
    background-color: ${props =>
      props.isDark ? props.theme.colors.dark2 : props.theme.colors.light2};
    color: ${props =>
      props.isDark ? props.theme.colors.light2 : props.theme.colors.dark2};
    min-width: 5rem;
    cursor: pointer;
    text-align: center;
  }

  .form-button:hover {
    border: 2px solid
      ${props =>
        props.isDark ? props.theme.colors.dark2 : props.theme.colors.light2};
    background-color: ${props =>
      props.isDark ? props.theme.colors.light2 : props.theme.colors.dark2};
    color: ${props =>
      props.isDark ? props.theme.colors.dark2 : props.theme.colors.light2};
  }

  .title {
    display: none;
  }

  .textArea {
    grid-column: 1 / span 2;
    grid-row: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media ${props => props.theme.breakpoints.mobile} {
   .modal-main {
      height: 22rem;
      width: 95%;
      top: 5rem;
    }
  }
`
