const snipcartFormInputsReducer = (
  state = {
    items: {},
  },
  action
) => {
  switch (action.type) {
    case "SNIPCART_FORM_ITEMS":
      return action.payload
    default:
      return state
  }
}
export default snipcartFormInputsReducer
