import darkModeReducer from './darkMode'
import spinnerReducer from './spinner'
import localeReducer from './locale'
import consentReducer from './consent'
import basketReducer from './basket'
import dashboardReducer from './dashboard'
import authResetStartedReducer from './authResetStarted'
import authResetSuccessReducer from './authResetSuccess'
import authFailureReducer from './authFailure'
import authResetSuccessConfirmReducer from './authResetConfirmSuccess'
import authResetStartedConfirmReducer from './authResetConfirmStarted'
import storeItemAddedSuccessReducer from './storeItemAddedSuccess'
import storeGetItemsReducer from './storeItems'
import storeFailureReducer from './storeFailure'
import critFormReducer from './critForm'
import formInputsReducer from './formInputs'
import talksReducer from './talkArray'
import dynamicImageReducer from './dynamicImage'
import optimisticImageReducer from './optimisticImage'
import imageDownloadReducer from './imageDownloaded'
import storeControllerReducer from './storeController'
import modalControllerReducer from './modalController'
import storeCreateReducer from './storeCreate'
import authRouterReducer from './authRouter'
import unAuthRouterReducer from './unauthRouter'
import optimistReducer from './optimist'
import hamburgerReducer from './hamburger'
import chatReducer from './chat'
import snipcartCreateReducer from './snipcartCreate'
import optimistSnipcartReducer from './optimistSnipcart'
import snipcartSitesReducer from './snipcartSites'
import snipcartImageReducer from './snipcartImage'
import snipcartFormInputsReducer from './snipcartFormInputs'
import {combineReducers} from 'redux'
const rootReducer = combineReducers({
  toggleDarkMode: darkModeReducer,
  localeString: localeReducer,
  consent: consentReducer,
  basket: basketReducer,
  dashboard: dashboardReducer,
  spinner: spinnerReducer,
  resetStarted: authResetStartedReducer,
  authFailure: authFailureReducer,
  resetSuccess: authResetSuccessReducer,
  resetConfirmStarted: authResetStartedConfirmReducer,
  resetConfirmSuccess: authResetSuccessConfirmReducer,
  storeItemAdded: storeItemAddedSuccessReducer,
  storeItems: storeGetItemsReducer,
  storeFailure: storeFailureReducer,
  critForm: critFormReducer,
  formInputs: formInputsReducer,
  talks: talksReducer,
  dynamicImage: dynamicImageReducer,
  optimisticImage: optimisticImageReducer,
  imageDownload: imageDownloadReducer,
  storeController: storeControllerReducer, 
  modalController: modalControllerReducer, 
  storeCreate: storeCreateReducer, 
  authRouter: authRouterReducer, 
  unAuthRouter: unAuthRouterReducer, 
  optimist: optimistReducer, 
  hamburger: hamburgerReducer, 
  chat: chatReducer, 
  snipcartCreate: snipcartCreateReducer, 
  optimistSnipcart: optimistSnipcartReducer, 
  snipcartSites: snipcartSitesReducer, 
  snipcartImage: snipcartImageReducer, 
  snipcartFormInputs: snipcartFormInputsReducer, 
})

export default rootReducer
