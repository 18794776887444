import React, { Component, createRef } from "react"
import { Hamburger2Wrapper } from "../elements"
import { Link } from "gatsby"
import { connect } from "react-redux"
import {
  Hamburger2Lines,
  Hamburger3Lines,
  // Hamburger3Body,
  Hamburger2Body,
  Hamburger4Body,
  // StripeBasket,
  SnipcartBasket,
} from "../components"
import { hamburger } from "../state/actions"
import Img from "gatsby-image"

import { StaticQuery, graphql } from "gatsby"
// import { SnipcartContext } from "gatsby-plugin-snipcart-advanced/store"

class Hamburger3 extends Component {
  parentRef = createRef()
  render() {
    const banner = bannerText()
    // console.log("test: ", testing.props.query)
    const isDark = this.props.isDark
    const changeRedux = this.props.menu
    let state = { ...changeRedux }.showMenu || false
    let state2 = { ...changeRedux }.showMenu2 || false
    if (changeRedux.escape) {
      this.parentRef.current.focus()
    }
    const url = this.props.url
    const locale = this.props.locale
    const nav = require("../utils/lingua")(locale)("NavbarTitle")
    let midLink = locale === "en" ? `${url}/en` : `${url}/ja`

    const burgerAction = evt => {
      if (evt.key === "Enter" || evt.nativeEvent.type === "click") {
        this.props.hamburger({ showMenu: !state, tab: "burger" })
      }
    }
    const burger2Action = evt => {
      if (evt.key === "Enter" || evt.nativeEvent.type === "click") {
        this.props.hamburger({ showMenu2: !state2, tab: "burger" })
      }
    }

    const dismissMenu = evt => {
      if (state) {
        this.props.hamburger({ showMenu: !state })
      }
    }

    return (
      <Hamburger2Wrapper state={state} state2={state2} isDark={isDark}>
        <div className={"navBar"}>
          <div
            className={"burgerContainer"}
            onClick={burgerAction}
            onKeyDown={burgerAction}
            role="button"
            tabIndex="0"
            ref={this.parentRef}
          >
            <Hamburger2Lines isDark={isDark} />
          </div>
          <div className={"mid"} tabIndex="-1">
            <Link to={midLink} onClick={dismissMenu} tabIndex="-1">
              <div className={"mid-wrapper"} tabIndex="-1">
                <div className={"mid-image"}>
                  <Img
                    fluid={isDark ? this.props.gdw : this.props.gdb}
                    style={{ width: "23px" }}
                    alt={"Site Logo and link to home page."}
                  />
                </div>
                <div className={"mid-text"}>
                  {banner}
                </div>
              </div>
            </Link>
          </div>

          <div className={"menu2"} role="button">
            <div
              onClick={burger2Action}
              onKeyDown={burger2Action}
              role="button"
              tabIndex={0}
            >
              <Hamburger3Lines isDark={isDark} location={this.props.location} />
            </div>
            <div>
              <SnipcartBasket isDark={isDark} location={this.props.location} />
            </div>
          </div>
        </div>
        <div className={"body"}>
          <Hamburger2Body isDark={isDark} change={state} url={this.props.url} />
        </div>
        <div className={"body2"}>
          <Hamburger4Body isDark={isDark} change={state2} />
        </div>
      </Hamburger2Wrapper>
    )
  }
}
// const burg = useRef()
const mapStateToProps = state => {
  return {
    menu: state.hamburger,
    locale: state.localeString,
  }
}

const mapDispatchToProps = () => {
  return {
    hamburger,
  }
}

export default connect(mapStateToProps, mapDispatchToProps(), null, {
  forwardRef: true,
})(Hamburger3)

const bannerText = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          site {
            siteMetadata {
              bannerText
            }
          }
        }
      `}
      render={data => (
        <div className={"wml-text"}>{data.site.siteMetadata.bannerText}</div>
      )}
    />
  )
}
