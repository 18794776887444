// https://uxdesign.cc/creating-horizontal-scrolling-containers-the-right-way-css-grid-c256f64fc585
import styled from "styled-components"
export const CarouselWrapper = styled.div`
--gutter: 1px;
.carouselRoot{
  padding: var(--gutter) 0;
  display: grid;
  grid-gap: var(--gutter) 0;
  grid-template-columns: var(--gutter) 1fr var(--gutter);
  align-content: start;
}
.productViewer{
  grid-column: 1 / span 2;
}
.carouselRoot > * {
  grid-column: 2 / -2;
}


.carouselRoot > .full {
  grid-column: 1 / -1;
}


.hs {
  display: grid;
  grid-gap: 1rem;
  grid-auto-flow: column;
  grid-auto-columns: 30%;
  
  
  grid-template-rows: minmax(150px, 1fr);
  
  overflow-x: scroll;
  scroll-snap-type: x proximity;
  padding-bottom: calc(.75 * var(--gutter));
  margin-bottom: calc(-.25 * var(--gutter));
}

@media ${props => props.theme.breakpoints.tablet} {
  .hs{
    grid-auto-columns: 40%;
  }
}
@media ${props => props.theme.breakpoints.mobile} {
  .hs{
    grid-auto-columns: 80%;
  }
}
`
export const CarouselImageWrapper = styled.div`
  // grid-column: ${props => (props.gridColumn ? props.gridColumn : "1")};
`
