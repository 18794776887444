const storeCreateReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case "STORE_CREATE":
      return action.payload
    default:
      return state
  }
}
export default storeCreateReducer
