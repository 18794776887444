import React from "react"
import { Hamburger2LinesWrapper } from "../elements"
import { useSelector } from "react-redux"

export const Hamburger2Lines = ({ location, isDark}) => {
  const change = useSelector(state => state.hamburger)
  let state = {...change}.showMenu
  return (
    <Hamburger2LinesWrapper change={state} isDark={isDark}>
        <div className={"bar1"}></div>
        <div className={"bar2"}></div>
        <div className={"bar3"}></div>
    </Hamburger2LinesWrapper>
  )
}
