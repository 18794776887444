// import  React, {useContext } from "react"
import  React from "react"
// import React from 'react'
import {FeatureImageWrapper} from '../elements'
import Img from 'gatsby-image'
import {useStaticQuery, graphql} from 'gatsby'
// import { ThemeManagerContext } from "gatsby-styled-components-dark-mode" 

export const FeatureImage = ({fixed, context}) => {
  const data = useStaticQuery(graphql`
  query{
    light: imageSharp(fluid: {originalName: {eq: "seoImage.png"}}) {
      fluid {
    ...GatsbyImageSharpFluid
    }
  }
}`)
// const themeContext = useContext(ThemeManagerContext)
let defaultImage = data.light.fluid
  return (
    <FeatureImageWrapper context={context}>
      <Img 
      fluid={fixed ? fixed : defaultImage} 
      style={{
        width: "50%",
        height: "100%"
      }} 
      />
    </FeatureImageWrapper>
  )
}