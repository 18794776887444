import styled from "styled-components"

export const HamburgerWrapper = styled.div`

  display: block;
  position: relative;
  padding-top: 5px;
  z-index: 20;
  user-select: none;
  label {
    display: none;
  }
  a {
    text-decoration: none;
    color: ${props =>
      props.isDark ? props.theme.colors.ed : props.theme.colors.dark1};

    transition: color 0.3s ease;
  }

  a:hover {
    color: tomato;
  }
  h2 {
    font-size: 3rem;
    color: ${props =>
      props.isDark ? props.theme.colors.ed : props.theme.colors.dark1};
  }
  h2:hover{
    color:tomato;
  }
  input {
    display: block;
    width: 40px;
    height: 32px;
    position: absolute;
    top: -7px;
    left: -5px;

    cursor: pointer;

    opacity: 0;
    z-index: 2;
  }

  span {
    display: block;
    width: 33px;
    height: 4px;
    margin-bottom: 5px;
    position: relative;

    background: ${props => props.isDark ? props.theme.colors.light2 : props.theme.colors.dark2};
    border-radius: 3px;

    z-index: 1;

    transform-origin: 4px 0px;

    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
      background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
  }

  span:first-child {
    transform-origin: 0% 0%;
  }

  span:nth-last-child(2) {
    transform-origin: 0% 100%;
  }

  input:checked ~ span {
    opacity: 1;
    transform: rotate(45deg) translate(-2px, -1px);

    background: ${props =>
      props.isDark ? props.theme.colors.ed : props.theme.colors.dark1};
  }

  input:checked ~ span:nth-last-child(3) {
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
  }

  input:checked ~ span:nth-last-child(2) {
    transform: rotate(-45deg) translate(0, -1px);
  }

  ul {
    position: absolute;
    width: 300px;
    margin: -100px 0 0 -50px;
    padding: 50px;
    padding-top: 125px;

    background: ${props =>
      props.isDark ? props.theme.colors.dark1 : props.theme.colors.ed};

    list-style-type: none;

    transform-origin: 0% 0%;
    transform: translate(-100%, 0);

    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  }

  li {
    padding: 10px 0;
    font-size: 22px;
  }
  input:hover ~ span {
    filter: brightness(110%);
  }
  input:checked ~ ul {
    transform: none;
  }
`
