/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getProduct = /* GraphQL */ `
  query GetProduct($id: ID!) {
    getProduct(id: $id) {
      id
      pictureName
      pictureOwner
      salesPitch
      sasPOS
      productTitle
      file {
        bucket
        region
        key
      }
      locale
      sort
      price
      tax
      sku
      POS
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listProducts = /* GraphQL */ `
  query ListProducts(
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        pictureName
        pictureOwner
        salesPitch
        sasPOS
        productTitle
        file {
          bucket
          region
          key
        }
        locale
        sort
        price
        tax
        sku
        POS
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getSnipcart = /* GraphQL */ `
  query GetSnipcart($id: ID!) {
    getSnipcart(id: $id) {
      id
      name
      price
      description
      url
      file {
        bucket
        region
        key
      }
      pictureName
      taxes
      disabled
      locale
      sort
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listSnipcarts = /* GraphQL */ `
  query ListSnipcarts(
    $filter: ModelSnipcartFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSnipcarts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        price
        description
        url
        file {
          bucket
          region
          key
        }
        pictureName
        taxes
        disabled
        locale
        sort
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
