import React from "react"
import { connect, useSelector } from "react-redux"
import { LogOutWrapper } from "../elements"
import {
  logout,
  authRouter,
  // itemAdded
} from "../state/actions"
const LogOut = ({ dispatch, isDark }) => {
  const loggedIn = useSelector(state => state.dashboard).logger
  const outClick = evt => {
    dispatch(logout())
    dispatch(authRouter("LOGIN"))
  }
  const outKey = evt => {
    if (evt.key === "Enter") {
      dispatch(logout())
      dispatch(authRouter("LOGIN"))
    }
  }
  if (loggedIn) {
    return (
      <LogOutWrapper 
        isDark={isDark} 
        onClick={outClick} 
        onKeyDown={outKey}
        tabIndex="0"
      >
        <div className={"lo"}>
          <h1>Log Out</h1>
        </div>
      </LogOutWrapper>
    )
  } else {
    return null
  }
}
const mapStateToProps = state => ({ dashboard: state.dashboard })
export default connect(state => mapStateToProps)(LogOut)
