import React from "react"
import {
  connect,
  // useSelector
} from "react-redux"
import // Auth
"aws-amplify"
import { spinner, reset, authFailure } from "../state/actions"
import { Messenger } from "../components"
import { LogInWrapper } from "../elements"

const ResetRequest = ({ dispatch, isDark, locale }) => {
  let lingua = require("../utils/lingua")(locale)("Auth")

  var inputs = {}
  const setSpinner = { visible: true, text: "...requesting change code" }

  const handleFormSubmission = evt => {
    evt.preventDefault()
    dispatch(authFailure({ visible: false }))
    dispatch(spinner(setSpinner))
    thunkReset()
  }

  const thunkReset = () => {
    dispatch(reset({ userID: inputs.username }))
  }

  const validateCodeForm = () => {
    return true
  }

  const handleInputChange = evt => {
    const { name, value, type, checked } = evt.target
    const check_type = ["radio", "checkbox"].includes(type)
    inputs[name] = check_type ? checked : value
    inputs["checkedValue"] = check_type ? value : null
  }

  const renderRequest = () => {
    return (
      <LogInWrapper isDark={isDark}>
        <div className={"reset"}>
          <form onSubmit={handleFormSubmission}>
            <div className="input2">
              <input
                className="rounded-input"
                id="username"
                key="username"
                name="username"
                type="username"
                onChange={handleInputChange}
                placeholder={lingua.username}
              />
            </div>
            <div className="input3">
              <button
                type="submit"
                className="form-button"
                disabled={!validateCodeForm()}
              >
                {lingua.sendConfirmation}
              </button>
            </div>
            <Messenger isDark={isDark} />
          </form>
        </div>
      </LogInWrapper>
    )
  }

  return renderRequest()
}

const mapStateToProps = state => ({ dashboard: state.dashboard })
export default connect(state => mapStateToProps)(ResetRequest)
