import styled from 'styled-components'
export const StoreEditButtonWrapper = styled.div`
  display: ${props => (props.visible ? `flex` : `none`)};
  justify-content: space-between;
  h3 {
    font-size: 1rem;
  }
  .edit-form-button {
    appearance: none;
    -webkit-appearance: none;
    border-radius: 2rem;
    padding: 10px;
    margin: 10px 0;
    border: 2px solid
      ${props =>
        props.isDark ? props.theme.colors.light2 : props.theme.colors.dark2};
    background-color: ${props =>
      props.isDark ? props.theme.colors.dark2 : props.theme.colors.light2};
    font-weight: 600;
    cursor: pointer;
    width: 7rem;
    text-align: center;
    font-size: 0.8rem;
    .edit-form-button:hover {
      border: 2px solid
      ${props =>
        props.isDark ? props.theme.colors.dark2 : props.theme.colors.light2};
       background-color: ${props =>
        props.isDark ? props.theme.colors.light2 : props.theme.colors.dark2};
      color: ${props =>
        props.isDark ? props.theme.colors.dark2 : props.theme.colors.light2};
      }
    }
`