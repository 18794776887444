module.exports = {
  metadata: {
    contributors: "D Fletcher",
  },
  components: [
  {
    name: `Snipcart`,
    text: {
      snipcart: `ず渡影代`,
      price: `最練さ`,
      productName: `づ日容せド夜`,
      productCopy:  `きでぜ頭毎まそル`,
      productImage: `切氏ス最練さきでぜ`,
      imageFileName: `切氏ス 最練さ`,
      findImageFile: `のろづ日容`,
      uploadProduct: `成切氏ス最練さ`,
      updateProduct: `転ワタ安8`,
      pageDrop: `のろづ日容`,
      dropdownMessage1: `曽ニ成切氏ス最`,
      dropdownMessage2: `曽ニ成切氏ス最`,
      order: `曽ニ成切氏ス:`,


    }
  },
  {
    name: `StoreFitter`,
    text: {
      storeFitter: `ず渡影代`,
      productName: `づ日容せド夜`,
      productCopy:  `きでぜ頭毎まそル`,
      productImage: `切氏ス最練さきでぜ`,
      imageFileName: `切氏ス 最練さ`,
      findImageFile: `のろづ日容`,
      uploadProduct: `成切氏ス最練さ`,
      updateProduct: `転ワタ安8`,
      posDrop: `のろづ日容`,
      posStripe: `ニ成切氏ス最`,
      posURL: `曽ニ成切氏ス最`,

    }
  },

    {
      name: `Auth`,
      text: {
        username: `のろづ日容`,
        password: `ト道晩ルラり閣宮`,
        resetPassword: `安81察柏沼6機象`,
        login: `づ日容せド夜`,
        sendConfirmation: `閣宮サヱエフ`,
        confirmationCode: `きでぜ頭毎まそル`,
        confirmationMessage: `ニル抗曽ニ成切氏ス最練さきで`,
        newPassword: `井けぱね漢虎よほも`,
        confirmPassword: `い死転ワタ安81察柏沼`,
        confirm: `曽ニ成切氏`,
        passwordErrorMessage: `ス初事著のろづ日容せド夜井けぱね漢虎よほもい死転ワタ安81察柏沼6機象連ン。朝`,
        passwordResetMessage: `ト道晩ルラり閣宮サヱエフ利対ぜむず渡影代チヒワオ給皮もいス務9寝死転ワタ安81察道ゅに大元ケモ需法シケヱ近与ばむ。`,
        formValidationError: `5神と申暮セニル抗曽ニ成切氏ス最練さきでぜ頭毎まそル力変41常み転傑ちぼ。破ヨワ整員ラレテソ今立ミ。`,
      },
    },
    {
      name: `Footer`,
      text: {
        login: `転ワタ安8`,
        dashboard: `けぱね漢`,
        chat: `キユケト`
      },
    },
    {
      name: `Dashboard`,
      text: {
        title: `むず渡影代チヒワ`,
        usernamePlaceholderText: `朝キユケト道晩`,
        passwordPlaceholderText: `けぱね漢虎よほ`,
        loginButtonText: `づ日容せド夜`,
        passwordReset: `ワタ安?`, 
      },
    },
    {
      name: `Home`,
      text: {
        title: `読承菱仲ざ。レホスカ`,
        artistStatement: `けぱね漢虎よほもい死転ワタ安81察柏沼6機象連ン。朝キユケト道晩ルラり閣宮サヱエフ利対ぜむず渡影代チヒワオ給皮もいス務9寝死転ワタ安81察道ゅに大元ケモ需法シケヱ近与ばむ。`,
        image: `seoImage.png`,
        HRText: `ハ会先ホ読承菱`,
      },
    },
    {
      name: `Gallery`,
      text: {
        heading: `ク録造夜ルん`,
        statement: `のろづ日容せド夜井けぱね漢虎よほもい死転ワタ安81察柏沼6機象連ン。朝キユケト道晩ルラり閣宮サヱエフ利対ぜむず渡影代チヒワオ給皮もいス務9寝死転ワタ安81察道ゅに大元ケモ需法シケヱ近与ばむ。`,
        image: `seoImage.png`,
        nextPage: `サヱエフ利対ぜ`,
        previousPage: `オ給皮もい`,
      },
    },
    {
      name: `ContactAndSales`,
      text: {
        email: `sales@gpsdmedia.co.uk`,
        title: `朝キユケト道晩ルラり閣`,
        statement: `学表ぱス初事著のろづ日容せド夜井けぱね漢虎よほもい死転ワタ安81察柏沼6機象連ン。朝キユケト道晩ルラり閣宮サヱエフ利対ぜむず渡影代チヒワオ給皮もいス務9寝死転ワタ安81察道ゅに大元ケモ需法シケヱ近与ばむ。`,
        image: `seoImage.png`,
        test: [
          {
            productImage: `product_01.webp`,
            price: `¥1000`,
            productTitle: `マヤツ家討東ノユセ1`,
            depop: `https://depop.com`,
            paypal: `https://paypal.com`,
            locale: `ja`,
            pitch: `分写到彫しぐ。道ナフ意5神と申暮セニル抗曽ニ成切氏ス最練さきでぜ頭毎まそル力変41常み転傑ちぼ。破ヨワ整員ラレテソ今立ミ`,
          },
          {
            productImage: `product_02.webp`,
            price: `¥1000`,
            productTitle: `マヤツ家討東ノユセ2`,
            depop: `https://depop.com`,
            paypal: `https://paypal.com`,
            locale: `ja`,
            pitch: `分写到彫しぐ。道ナフ意5神と申暮セニル抗曽ニ成切氏ス最練さきでぜ頭毎まそル力変41常み転傑ちぼ。破ヨワ整員ラレテソ今立ミ`,
          },
          {
            productImage: `product_03.webp`,
            price: `¥1000`,
            productTitle: `マヤツ家討東ノユセ3`,
            depop: `https://depop.com`,
            paypal: `https://paypal.com`,
            locale: `ja`,
            pitch: `分写到彫しぐ。道ナフ意5神と申暮セニル抗曽ニ成切氏ス最練さきでぜ頭毎まそル力変41常み転傑ちぼ。破ヨワ整員ラレテソ今立ミ`,
          },
          {
            productImage: `product_04.webp`,
            price: `¥1000`,
            productTitle: `マヤツ家討東ノユセ4`,
            depop: `https://depop.com`,
            paypal: `https://paypal.com`,
            locale: `ja`,
            pitch: `分写到彫しぐ。道ナフ意5神と申暮セニル抗曽ニ成切氏ス最練さきでぜ頭毎まそル力変41常み転傑ちぼ。破ヨワ整員ラレテソ今立ミ`,
          },
          {
            productImage: `product_05.webp`,
            price: `¥1000`,
            productTitle: `マヤツ家討東ノユセ5`,
            depop: `https://depop.com`,
            paypal: `https://paypal.com`,
            locale: `ja`,
            pitch: `分写到彫しぐ。道ナフ意5神と申暮セニル抗曽ニ成切氏ス最練さきでぜ頭毎まそル力変41常み転傑ちぼ。破ヨワ整員ラレテソ今立ミ`,
          },
          {
            productImage: `product_06.webp`,
            price: `¥1000`,
            productTitle: `マヤツ家討東ノユセ6`,
            depop: `https://depop.com`,
            paypal: `https://paypal.com`,
            locale: `ja`,
            pitch: `分写到彫しぐ。道ナフ意5神と申暮セニル抗曽ニ成切氏ス最練さきでぜ頭毎まそル力変41常み転傑ちぼ。破ヨワ整員ラレテソ今立ミ`,
          },
          {
            productImage: `product_07.webp`,
            price: `¥1000`,
            productTitle: `マヤツ家討東ノユセ7`,
            depop: `https://depop.com`,
            paypal: `https://paypal.com`,
            locale: `ja`,
            pitch: `分写到彫しぐ。道ナフ意5神と申暮セニル抗曽ニ成切氏ス最練さきでぜ頭毎まそル力変41常み転傑ちぼ。破ヨワ整員ラレテソ今立ミ`,
          },
          {
            productImage: `product_08.webp`,
            price: `¥1000`,
            productTitle: `マヤツ家討東ノユセ8`,
            depop: `https://depop.com`,
            paypal: `https://paypal.com`,
            locale: `ja`,
            pitch: `分写到彫しぐ。道ナフ意5神と申暮セニル抗曽ニ成切氏ス最練さきでぜ頭毎まそル力変41常み転傑ちぼ。破ヨワ整員ラレテソ今立ミ`,
          },
          {
            productImage: `product_09.webp`,
            price: `¥1000`,
            productTitle: `マヤツ家討東ノユセ9`,
            depop: `https://depop.com`,
            paypal: `https://paypal.com`,
            locale: `ja`,
            pitch: `分写到彫しぐ。道ナフ意5神と申暮セニル抗曽ニ成切氏ス最練さきでぜ頭毎まそル力変41常み転傑ちぼ。破ヨワ整員ラレテソ今立ミ`,
          },
          {
            productImage: `product_10.webp`,
            price: `¥1000`,
            productTitle: `マヤツ家討東ノユセ10`,
            depop: `https://depop.com`,
            paypal: `https://paypal.com`,
            locale: `ja`,
            pitch: `分写到彫しぐ。道ナフ意5神と申暮セニル抗曽ニ成切氏ス最練さきでぜ頭毎まそル力変41常み転傑ちぼ。破ヨワ整員ラレテソ今立ミ`,
          },
        ],
      },
    },
    {
      name: `404`,
      text: `ス初事著のろづ日容せド夜井けぱね漢虎よほもい死転ワタ安81察柏沼6機象連ン。朝`,
    },
    {
      name: `Hamburger`,
      text: {
        title: `歯33定材`,
        gallery: `事著のろづ日容せ`,
        contact: `皮もいス務9寝`,
        storeFitter: `漢虎よほもい`,
        snipcart: `破ヨワ整員ラレテ`,
        chat: `ス初事著のろ`,
      },
    },
    {
      name: `NavbarTitle`,
      text: {
        row1: `読承菱仲`,
        row2: `ざ`,
        row3: `。レホスカ`,
      },
    },
    {
      name: `Months`,
      text: [
        "ぼ金筋き",
        "ほもい死転ワ",
        "の現性ば小",
        "務9寝死転",
        "ワキ年思ぐ",
        "通交おね",
        "疑下レホス",
        "ル額和ムマ",
        "ヌヘマ真泳",
        "ミ員南やー",
        "報審ラリハ",
        "フソハ会",
      ],
    },
  ],
}
