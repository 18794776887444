const storeGetItemsReducer = (
  state = {
    items: [],
  },
  action
) => {
  switch (action.type) {
    case "TALKS":
      return action.payload
    default:
      return state
  }
}

export default storeGetItemsReducer
