import React, { useContext } from "react"
import { 
  Container, 
  Auth2,
} from "../components"
import { ThemeManagerContext } from "gatsby-styled-components-dark-mode"
import { useSelector } from "react-redux"

export const Dashboard = ({ location }) => {
  const themeContext = useContext(ThemeManagerContext)
  const isDark = themeContext.isDark
  const locale = useSelector(state => state.localeString)
  let logger = useSelector(state => state.dashboard).logger
  // let uAR = null
  // if(logger){
  //   dispatch(unAuthRouter(null))
  // }else{
  //   dispatch(unAuthRouter(""))
  //   uAR=""
  // }
  return (
    <Container location={location.pathname}>
      <Auth2
        isDark={isDark}
        locale={locale}
        location={location.pathname}
        unAuthRouter={""}
        logger={{ ...logger }}
      />
    </Container>
  )
}
export default Dashboard