import React from "react"
import { connect, useSelector } from "react-redux"
import { StoreCardFormWrapper } from "../elements"

import { SnipcartImage, FormHR } from "../components"

import {
  snipcartImage,
  snipcartFormInputs,
  optimisticUploadSnipcart,
  modalController,
  snipcartCreate
} from "../state/actions"
import { v4 as uuidv4 } from "uuid"

const SnipcartFormCreate = ({ locale, dispatch, isDark, store }) => {
  const id = 'item-create-id'
  const snipcartCreateRedux = useSelector(state => state.snipcartCreate)

  const optimisticImagesStore = useSelector(state => state.snipcartImage)
  
  const inputsStore = useSelector(state => state.snipcartFormInputs).items
  
  let upload
  
  const lingua = require("../utils/lingua")(locale)("Snipcart")
  
  let optimisticImages = { ...optimisticImagesStore }
  
  let inputs = { ...inputsStore[id] }

  const handleInputChange = evt => {
    const { name, value, type, checked } = evt.target
    const check_type = ["radio", "checkbox"].includes(type)
    inputs[name] = check_type ? checked : value
    inputs["checkedValue"] = check_type ? value : null
    inputsStore[id] = inputs

    dispatch(snipcartFormInputs({ items: inputsStore }))
  }

  const validated = () => {
    return !(
      inputs.name === "" ||
      inputs.name === undefined ||
      inputs.description === "" ||
      inputs.description === undefined ||
      inputs.imageFile === "" ||
      inputs.imageFile === undefined ||
      inputs.pictureName === "" ||
      inputs.pictureName === undefined ||
      inputs.locale === "" ||
      inputs.locale === undefined ||
      inputs.url === "" ||
      inputs.url === undefined ||
      !/^[0-9]+$/.test(inputs.price) ||
      !/^[0-9]+$/.test(inputs.sort)
    )
  }

  const validationErrorMessage = () => {
    let message = "Empty :"
    let double = false
    if (inputs.name === "" || inputs.name === undefined) {
      message += " product name"
      double = true
    }
    if (inputs.description === "" || inputs.description === undefined) {
      console.log("Description error detected.")
      if (double) {
        message += ","
      }
      message += " sales copy"
      double = true
    }
    if (inputs.price === "" || inputs.price === undefined) {
      if (double) {
        message += ","
      }
      message += " price"
      double = true
    }
    if (inputs.url === "" || inputs.url === undefined) {
      if (double) {
        message += ","
      }
      message += " page"
      double = true
    }
    if (inputs.imageFile === "" || inputs.imageFile === undefined) {
      if (double) {
        message += ","
      }
      message += " image"
    }
    if (!/^[0-9]+$/.test(inputs.price)) {
      if (double) {
        message += " Price is not a number"
      } else {
        message = "Price is not a number."
      }
    }
    // if (!/^[0-9]+$/.test(inputs.sort)) {
    //   if (double) {
    //     message += " Running order is not a number."
    //   } else {
    //     message = "Running order is not a number."
    //   }
    // }
    message += "."
    return message
  }

  const createFormButtonActionKey = evt => {
    if (evt.nativeEvent.key === "Enter") createAction()
  }

  const createFormButtonActionMouse = evt => { createAction() }

  const createAction = evt => {
    inputs.locale = locale
    // inputs.price = inputs.price * 100
    if (validated()) {
      let local_inputsStore = {}
      local_inputsStore.items = inputsStore
      let local_inputs = inputs
      local_inputs.id = uuidv4()
      local_inputsStore[id] = null
      local_inputsStore.items[local_inputs.id] = local_inputs
      let tmp = optimisticImages[id]
      optimisticImages[id] = null
      optimisticImages[inputs.id] = tmp

      dispatch(snipcartCreate({ visible: false }))
      dispatch(snipcartImage(optimisticImages))
      dispatch(optimisticUploadSnipcart(local_inputs, store))
      dispatch(snipcartFormInputs(local_inputsStore))

    } else {
      let modal = {
        type: "ok",
        title: "Incomplete Form",
        content: validationErrorMessage(),
      }
      dispatch(modalController(modal))
    }
  }
  const snipcarts = require('../../availableURLS.json')[locale]

  return (
    <StoreCardFormWrapper isDark={isDark} visible={snipcartCreateRedux.visible}>
      <div className={"container"}>
        <>
          <div className={"createTitle"}>
            <h1>Create an Item</h1>
          </div>
          <div className={"cardTitle"}>
            <div className={"horizontalRule"}>
              <FormHR HRName={"HRText"} lingua={lingua.productName} />
            </div>
            <div className={"cardFormTitleInput"}>
              <div className="input1">
                <input
                  className="rounded-input"
                  // id="productName"
                  key="name"
                  name="name"
                  onChange={handleInputChange}
                  type="text"
                  placeholder={lingua.productName}
                  tabIndex="0"
                  value={
                    Object.prototype.hasOwnProperty.call(inputs, "name")
                      ? inputs.name
                      : ""
                  }
                />
              </div>
            </div>
          </div>
          <div className={"cardPrice"}>
            <div className={"horizontalRule"}>
              <FormHR HRName={"HRText"} lingua={lingua.price} />
            </div>
            <div className={"cardFormTitleInput"}>
              <div className="input1">
                <input
                  className="rounded-input"
                  key="price"
                  name="price"
                  onChange={handleInputChange}
                  placeholder={lingua.price}
                  tabIndex="0"
                  value={
                    Object.prototype.hasOwnProperty.call(inputs, "price")
                      ? inputs.price
                      : ""
                  }
                />
              </div>
            </div>
          </div>
          <div className={"cardImageForm"}>
            <div className={"horizontalRule"}>
              <FormHR HRName={"HRText"} row={5} lingua={lingua.productImage} />
            </div>
            <div className={"cardImage"}>
              <SnipcartImage
                id={id}
                file={undefined}
                isDark={isDark}
                locale={locale}
                tabIndex="0"
              />
            </div>
            <div></div>
            <label
              className={"form-button"}
              htmlFor="fileCreate"
              tabIndex="0"
              onKeyPress={evt => {
                if (evt.key === "Enter") {
                  upload.click()
                }
              }}
            >
              <input
                type="file"
                accept="image/png, image/jpeg"
                id="fileCreate"
                name="fileCreate"
                ref={ref => (upload = ref)}
                onChange={evt => {
                  let path = URL.createObjectURL(upload.files[0])
                  inputsStore[id] = inputsStore[id] || {}
                  inputsStore[id].imageFile = upload.files[0]
                  inputsStore[id].pictureName = upload.files[0].name
                  inputsStore[id].type = upload.files[0].type
                  dispatch(snipcartFormInputs({ items: inputsStore }))
                  optimisticImages[id] = optimisticImages[id] || {}
                  optimisticImages[id] = {
                    id: id,
                    file: path,
                    label: upload.files[0].name,
                  }
                  dispatch(snipcartImage(optimisticImages))
                }}
              />
              {lingua.findImageFile}
            </label>
          </div>

          <div className={"cardCopy"}>
            <div className={"horizontalRule"}>
              <FormHR HRName={"HRText"} row={5} lingua={"Copy"} />
            </div>
            <textarea
              className="rounded-area"
              id="description"
              key="description"
              name="description"
              type="text"
              tabIndex="0"
              value={
                Object.prototype.hasOwnProperty.call(inputs, "description")
                  ? inputs.description
                  : ""
              }
              onChange={handleInputChange}
              placeholder={"Copy"}
            />
          </div>

          <div className={"pageDropdown"}>
            <FormHR HRName={"HRText"} lingua={lingua.pageDrop} />
            <div className={"select"}>
              <label htmlFor="url" />
              <select
                id="url"
                name="url"
                onChange={handleInputChange}
                value={
                  Object.prototype.hasOwnProperty.call(inputs, "url")
                    ? inputs.url
                    : ""
                }
              >
                <option hidden={true}>{lingua.dropdownMessage1}</option>
                <option disabled="disabled" default={true}>
                  {lingua.dropdownMessage2}
                </option>
                {snipcarts.map(item => {
                  return (
                    <option value={item} key={uuidv4()}>
                      {item}
                    </option>
                  )
                })}
              </select>
            </div>
            <div className={"orderValue"}>
              <h1>{lingua.order}:</h1>

              <div className="input1">
                <input
                  className="rounded-input"
                  key="sort"
                  name="sort"
                  onChange={handleInputChange}
                  placeholder={lingua.order}
                  tabIndex="0"
                  value={
                    Object.prototype.hasOwnProperty.call(inputs, "sort")
                      ? inputs.sort
                      : ""
                  }
                />
              </div>
            </div>
          </div>



          {/* <div className={"upload"}>
            <input className={"upload"} type="submit" tabIndex="0" value={lingua.uploadProduct}/>
            </div> */}
          <div className={"upload"}>
            <div
              tabIndex="0"
              role="button"
              className={"form-button"}
              onKeyPress={e => {
                createFormButtonActionKey(e)
              }}
              onClick={e => {
                createFormButtonActionMouse(e)
              }}
            >
              {lingua.uploadProduct}
            </div>
          </div>
        </>
      </div>
    </StoreCardFormWrapper>
  )
}
const mapStateToProps = state => ({ localeString: state.local })
export default connect(state => mapStateToProps)(SnipcartFormCreate)
