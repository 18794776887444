const basketReducer = (state = {}, action) => {
  switch (action.type) {
    case "BASKET":
      if (action.payload === state) {
        return {}
      } else {
        return action.payload
      }
    default:
      return state
  }
}

export default basketReducer
