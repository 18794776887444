import styled from 'styled-components'
export const DynamicImageWrapper = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
align-content: center;
.imageElement{
  width: 100%;
  object-fit: cover;
}
.imageLabel{
  margin: 0;
  overflow: hidden;
  padding: 1rem 0;
}
h4{
  font-size: 0.8rem;
  width: 100%;
}
@media ${props => props.theme.breakpoints.mobile} {
  
}
`