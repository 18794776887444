const spinnerReducer = (
  state = {
    visible: false,
    text: "",
  },
  action
) => {
  switch (action.type) {
    case "SPINNER":
      return action.payload
    default:
      return state
  }
}

export default spinnerReducer
