import styled from "styled-components"
export const StoreWrapper = styled.div`
background-color: ${props => props.theme.colors.light2};
background-color: inherit;
grid-column: 1 / span 2;

display: block
  z-index: 1;
  padding: ${props =>
    `${props.theme.spacings.xLarge} ${props.theme.spacings.xxLarge}`};
    
    .waiting{
      padding: 1rem;
    }
  h1 {
    color: inherit;
  }
  p {
    color: inherit;
  }
  @media ${props => props.theme.breakpoints.tablet} {
    display: block;
    grid-column: 1 / span 2;
  }
  
  @media ${props => props.theme.breakpoints.mobile} {
    padding: ${props =>
      `${props.theme.spacings.small} ${props.theme.spacings.small}`};
  }
`
export const FitterWrapper = styled.div`
  background-color: ${props => props.theme.colors.light2};
  background-color: inherit;
  grid-column: 1 / span 2;
  // grid-row: 2;
  display: block;
  z-index: 1;

  h1 {
    color: inherit;
    font-size: 2rem;
  }
  h2 {
    color: inherit;
    font-size: 3rem;
    padding-bottom: 0.4rem;
  }
  h3 {
    color: ${props =>
      props.isDark ? props.theme.colors.light2 : props.theme.colors.dark2};
    text-align: center;
    font-size: 0.4rem;
  }

  p {
    color: inherit;
  }
  .controlWrapper {
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-template-rows: auto auto;
  }
  .title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.5rem;
  }
  .createProduct {
    grid-column: 1 / span 2;
  }
  .form-button {
    grid-column: 2;
    appearance: none;
    -webkit-appearance: none;
    border-radius: 2rem;
    padding: 10px;
    margin: 10px 0;
    border: 2px solid
      ${props =>
        props.isDark ? props.theme.colors.light2 : props.theme.colors.dark2};
    background-color: ${props =>
      props.isDark ? props.theme.colors.dark2 : props.theme.colors.light2};
    color: ${props =>
      props.isDark ? props.theme.colors.light2 : props.theme.colors.dark2};
    font-weight: 600;
    // width: 100%;
    cursor: pointer;
    text-align: center;
    font-size: 0.8rem;
  }
  .form-button:hover {
    border: 2px solid
      ${props =>
        props.isDark ? props.theme.colors.dark2 : props.theme.colors.light2};
    background-color: ${props =>
      props.isDark ? props.theme.colors.light2 : props.theme.colors.dark2};
    color: ${props =>
      props.isDark ? props.theme.colors.dark2 : props.theme.colors.light2};
  }

  @media ${props => props.theme.breakpoints.tablet} {
    display: block;
    grid-column: 1 / span 2;
  }

  @media ${props => props.theme.breakpoints.mobile} {
  }
`

export const StoreCardWrapper = styled.div`
display: ${props => (props.visible ? `grid` : `none`)};

  width: 100%;
  box-shadow: ${props =>
    props.isDark ? props.theme.shadows.shadow2 : props.theme.shadows.shadow1};
    margin: 1rem 0;
    padding: 1rem;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto auto auto;
    grid-row-gap: 1rem;
    grid-column-gap: 1rem;
    .cardTitle {
      grid-row: 1;
      grid-column: 1 /span 2;
      display: flex;
      justify-content: center;
      align-items: center;
      h1{
        font-size: 3rem;
      }
    }
    .cardImage {
      grid-row: 2 / span 2;
      grid-column: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  .imageElement{
    width: 100%;
    object-fit: cover;
  }
  .cardPitch {
    grid-column: 2;
    grid-row: 2 / span 2;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    p{
      font-size: 2.5rem;
    }
  }
  .cardBuy {
    grid-row: 4;
    grid-column: 1 / span 2;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .buyButton {
    appearance: none;
    -webkit-appearance: none;
    border-radius: 2rem;
    padding: 10px;
    margin: 10px 0;
    border: 2px solid
      ${props =>
        props.isDark ? props.theme.colors.light2 : props.theme.colors.dark2};
    background-color: ${props =>
      props.isDark ? props.theme.colors.dark2 : props.theme.colors.light2};
    color: ${props =>
      props.isDark ? props.theme.colors.light2 : props.theme.colors.dark2};
    font-weight: 600;
    width: 10rem;
    cursor: pointer;
    text-align: center;
  }
  .buyButton:hover {
    border: 2px solid
      ${props =>
        props.isDark ? props.theme.colors.dark2 : props.theme.colors.light2};
    background-color: ${props =>
      props.isDark ? props.theme.colors.light2 : props.theme.colors.dark2};
    color: ${props =>
      props.isDark ? props.theme.colors.dark2 : props.theme.colors.light2};
    }
    h1{
      font-size: 1rem;
    }
    p{
      font-size: 1.4rem;
    }
  @media ${props => props.theme.breakpoints.tablet}{
    h1{
      font-size: 1rem;
    }
    p{
      font-size: 1.4rem;
    }
  }

  @media ${props => props.theme.breakpoints.mobile}{
    .cardImage{
      grid-row:2;
      grid-column: 1 / span 2;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .cardPitch{
      height: 100%;
      grid-row: 3;
      grid-column: 1 / span 2;
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
      p{
        font-size: 1.5rem;
      }
    }
    .cardBuy{
      grid-column: 1 / span 2;
      grid-row:4;
      display: flex;
      align-items: center;
      justify-content: center;
    }
`
// ________________________________________________________
export const StoreCardFormWrapper = styled.div`
  display: ${props => (props.visible ? `flex` : `none`)};
  flex-direction: column;
  background: rgba(0, 255, 42, 0.1);
  box-shadow: ${props =>
    props.isDark ? props.theme.shadows.shadow2 : props.theme.shadows.shadow1};
  // margin: 1rem 0;
  // padding: 1rem;
  // border: 0.1rem solid black;
  input[type="file"] {
    display: none;
}
.fileLabel{
  border
}
.createTitle{
  h1{
    text-align: center;
    font-size: 2.5rem;
  }
}
h4{
  text-align: center;
  font-size: 0.8rem;
  padding-top: 0.5rem;
}
.rounded-input {
  border: 0;
  box-shadow: 0 0 15px 4px rgba(0, 0, 0, 0.06);
  padding: 10px;
  border-radius: 2rem;
  // margin: 10px 0;
  width: 100%;
}
.rounded-area {
  // padding: 10px;
  border: 0;
  box-shadow: 0 0 15px 4px rgba(0, 0, 0, 0.06);
  padding: 10px;
  border-radius: 0.5rem;
  // margin: 10px 0;
  width: 100%;
  height: 5rem;
}
.form-button {
  appearance: none;
  -webkit-appearance: none;
  border-radius: 2rem;
  padding: 10px;
  margin: 10px 0;
  border: 2px solid
    ${props =>
      props.isDark ? props.theme.colors.light2 : props.theme.colors.dark2};
  background-color: ${props =>
    props.isDark ? props.theme.colors.dark2 : props.theme.colors.light2};
  color: ${props =>
    props.isDark ? props.theme.colors.light2 : props.theme.colors.dark2};
  font-weight: 600;
  width: 100%;
  cursor: pointer;
  text-align: center;
  font-size: 0.8rem;
}
.form-button:hover {
  border: 2px solid
  ${props =>
    props.isDark ? props.theme.colors.dark2 : props.theme.colors.light2};
    background-color: ${props =>
      props.isDark ? props.theme.colors.light2 : props.theme.colors.dark2};
      color: ${props =>
        props.isDark ? props.theme.colors.dark2 : props.theme.colors.light2};
}

.horizontalRule {
padding-top: 2rem;
display: grid;
}

.imageElement {
width: 100%;
object-fit: cover;
}
.posDropdown {
  padding-top: 2rem;
  display: grid;
  grid-template-rows: auto auto;
}
.pageDropdown {
  padding-top: 2rem;
  display: grid;
  grid-template-rows: auto auto;
}
.cardTitle {
  display: grid;
  grid-template-rows: auto auto;
}
.cardPrice {
  display: grid;
  grid-template-rows: auto auto;
}
select{
  padding: 0.4rem;

  border-radius: 2rem;
}
.select{
padding-bottom: 2rem;
}
.cardImageForm{
  display: flex;
  flex-direction: column;
}
.cardImage {
  padding-bottom: 0;
  padding-top: 0;
  
  // grid-template-rows: auto auto;
  // grid-row: 2 / span 2;
  // grid-column: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

// .cardImage {
  //   grid-template-rows: auto auto;
  //   display: grid;
  // }
  .cardCopy {
    display: grid;
    grid-template-rows: auto auto;
  }
  .cardPOS {
    display: grid;
    grid-template-rows: auto auto;
  }
  .upload{
    padding-top: 3rem;
  }
  .orderValue{
    display: flex;
    justify-content: space-around;
    align-items: baseline;

    
    h1{
      font-size: 1rem;
    }
  }
  @media ${props => props.theme.breakpoints.mobile} {
    .container{
      grid-column: 1 / span 2;
  }
}
`
// __________________________________________________
export const FormHRWrapper = styled.div`
  display: grid;
  grid-auto-columns: auto 1fr;
  grid-row: ${props => props.row};
  padding-bottom: 1rem;
  h4 {
    grid-column: 1;
    font-weight: bold;
    color: ${props =>
      props.isDark ? props.theme.colors.light3 : props.theme.colors.dark2};
  }
  p {
    grid-column: 1;
    grid-row: 1;
    font-weight: bold;
    color: ${props =>
      props.isDark ? props.theme.colors.light3 : props.theme.colors.dark2};
  }
  hr {
    grid-column: 2;
    margin-top: 0.45rem;
    margin-bottom: 0.45rem;
    border-top: 0.15rem solid
      ${props =>
        props.isDark ? props.theme.colors.light3 : props.theme.colors.dark2};
    border-bottom: 0;
    border-radius: 0.15rem;
  }
  width: 100%;
  @media ${props => props.theme.breakpoints.tablet} {
  }

  @media ${props => props.theme.breakpoints.mobile} {
    grid-row: ${props => props.row};
    hr {
      grid-column: 2;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      border-top: 0.15rem solid
        ${props =>
          props.isDark ? props.theme.colors.light3 : props.theme.colors.dark2};
      border-bottom: 0;
      border-radius: 0.15rem;
    }
  }
`

export const StoreViewControllerWrapper = styled.div`
  .form-button {
    appearance: none;
    -webkit-appearance: none;
    border-radius: 2rem;
    padding: 10px;
    margin: 10px 0;
    border: 2px solid
      ${props =>
        props.isDark ? props.theme.colors.light2 : props.theme.colors.dark2};
    background-color: ${props =>
      props.isDark ? props.theme.colors.dark2 : props.theme.colors.light2};
    color: ${props =>
      props.isDark ? props.theme.colors.light2 : props.theme.colors.dark2};
    font-weight: 600;
    width: 100%;
    cursor: pointer;
    text-align: center;
    font-size: 0.8rem;
    :focus {
      border: 2px solid
        ${props =>
          props.isDark ? props.theme.colors.dark2 : props.theme.colors.light2};
      background-color: ${props =>
        props.isDark ? props.theme.colors.light2 : props.theme.colors.dark2};
      color: ${props =>
        props.isDark ? props.theme.colors.dark2 : props.theme.colors.light2};
    }
  }
  .edit {
    display: ${props => (props.show ? `block` : `none`)};
  }
`
