const dashboardReducer = (
  state = {
    logger: false,
  },
  action
) => {
  switch (action.type) {
    case "DASHBOARD":
      return action.payload
    default:
      return state
  }
}

export default dashboardReducer
