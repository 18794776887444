const authFailureReducer = (
  state = {
    message: "",
    visible: false,
  },
  action
) => {
  switch (action.type) {
    case "AUTH_FAILURE":
      return action.payload
    default:
      return state
  }
}

export default authFailureReducer
