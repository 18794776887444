import styled from "styled-components"
export const ProductViewerWrapper = styled.div`
display: grid;
grid-template-rows: 1fr auto auto;
grid-template-columns: 1fr;
font-size: 1.5rem;
h1{
  grid-row: 1;
  grid-column: 1 / span 2;
  margin: 2rem 0;
  font-size: 2rem;
}
p{
  text-align: center;
  grid-row: 2;
  grid-column: 1 / span 2;
}
.funds{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin: 2rem 0;
  align-items: center;
  object-fit: cover;
  grid-row: 3;
}
`