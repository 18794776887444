const authResetSuccessConfirmReducer = (
  state = {
    confirmMessage: "",
    resetSuccess: false,
  },
  action
) => {
  switch (action.type) {
    case "AUTH_RESET_CONFIRM_SUCCESS":
      return action.payload
    default:
      return state
  }
}

export default authResetSuccessConfirmReducer
