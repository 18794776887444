// import React from "react"
import React, { Component, createRef } from "react"

import { connect } from "react-redux"
import {
  // loginStatus,
  login,
  spinner,
  unAuthRouter,
} from "../state/actions"
import { LogInWrapper, ResetWrapper } from "../elements"
import { Messenger } from "../components"

// const LogIn = ({ dispatch, isDark, locale }) => {
class LogIn extends Component {
  parentRef = createRef()
  componentDidMount(){
    this.parentRef.current.focus()
  }
  render() {
    const lingua = require("../utils/lingua")(this.props.locale)("Auth")
    var inputs = {}
    const setSpinner = { visible: true, text: "...requesting change code" }

    const handleFormSubmission = evt => {
      evt.preventDefault()
      this.props.spinner(setSpinner)
      signIn()
    }

    const signIn = () => {
      const username = inputs.username
      const password = inputs.password
      this.props.login({ userName: username, password: password })
    }

    const handleInputChange = evt => {
      const { name, value, type, checked } = evt.target
      const check_type = ["radio", "checkbox"].includes(type)
      inputs[name] = check_type ? checked : value
      inputs["checkedValue"] = check_type ? value : null
    }

    const resetRequested = evt => {
      if (evt.keyCode === 13 || evt.nativeEvent.type === "click") {
        this.props.unAuthRouter("RESET_ACCOUNT")
      }
    }

    return (
      <LogInWrapper isDark={this.props.isDark}>
        <form onSubmit={handleFormSubmission} className={"formElement"}>
          <div className="input1">
            <input
              className="rounded-input"
              id="username"
              key="username"
              name="username"
              onChange={handleInputChange}
              type="text"
              placeholder={lingua.username}
              ref={this.parentRef}
            />
          </div>
          <div className="input2">
            <input
              className="rounded-input"
              id="password"
              key="password"
              name="password"
              onChange={handleInputChange}
              type="password"
              placeholder={lingua.password}
            />
          </div>
          <ResetWrapper isDark={this.props.isDark}>
            <div
              className={"reset"}
              onClick={resetRequested}
              onKeyDown={resetRequested}
              role="button"
              tabIndex="0"
            >
              {lingua.resetPassword}
            </div>
          </ResetWrapper>
          <div className="input3">
            <button
              type="submit"
              onClick={handleFormSubmission}
              className="form-button"
            >
              {lingua.login}
            </button>
          </div>
          <Messenger isDark={this.props.isDark} />
        </form>
      </LogInWrapper>
    )
  }
}
// const mapStateToProps = state => {
//   return {
//     menu: state.hamburger,
//     locale: state.localeString,
//   }
// }

const mapDispatchToProps = () => {
  return {
    login,
    spinner,
    unAuthRouter,
  }
}

export default connect(null, mapDispatchToProps(), null, {
  forwardRef: true,
})(LogIn)
// const mapStateToProps = state => ({ dashboard: state.dashboard })
// export default connect(state => mapStateToProps)(LogIn)
