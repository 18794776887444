import styled from "styled-components"
export const SnipcartDashboardWrapper = styled.div`
  background-color: ${props => props.theme.colors.light2};
  background-color: inherit;
  grid-column: 1 / span 2;
  // grid-row: 2;
  display: block;
  z-index: 1;

  h1 {
    color: inherit;
    font-size: 2rem;
  }
  h2 {
    color: inherit;
    font-size: 2.4rem;
    padding-bottom: 0.4rem;
  }
  h3 {
    color: ${props =>
      props.isDark ? props.theme.colors.light2 : props.theme.colors.dark2};
    text-align: center;
    font-size: 0.4rem;
  }

  p {
    color: inherit;
  }
  .controlWrapper {
    display: grid;
    grid-template-columns: 6fr 1fr;
    grid-template-rows: auto auto;
  }
  .position-and-create {
    grid-column: 2;
    grid-row: 1;
    display: flex;
  }
  .title {
    grid-column: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.5rem;
  }
  .createProduct {
    grid-column: 1 / span 2;
    grid-row: 2;
  }
  .sort-buttons{
    grid-column: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
  .create-button {
    grid-column: 2;
    appearance: none;
    -webkit-appearance: none;
    border-radius: 2rem;
    padding: 10px;
    margin: 10px 0;
    border: 2px solid
      ${props =>
        props.isDark ? props.theme.colors.light2 : props.theme.colors.dark2};
    background-color: ${props =>
      props.isDark ? props.theme.colors.dark2 : props.theme.colors.light2};
    color: ${props =>
      props.isDark ? props.theme.colors.light2 : props.theme.colors.dark2};
    font-weight: 600;
    // width: 100%;
    cursor: pointer;
    text-align: center;
    font-size: 0.8rem;
    :hover {
      border: 2px solid
        ${props =>
          props.isDark ? props.theme.colors.dark2 : props.theme.colors.light2};
      background-color: ${props =>
        props.isDark ? props.theme.colors.light2 : props.theme.colors.dark2};
      color: ${props =>
        props.isDark ? props.theme.colors.dark2 : props.theme.colors.light2};
    }
  }


  .form-button {
    grid-column: 2;
    appearance: none;
    -webkit-appearance: none;
    border-radius: 2rem;
    padding: 10px;
    margin: 10px 0;
    border: 2px solid
      ${props =>
        props.isDark ? props.theme.colors.light2 : props.theme.colors.dark2};
    background-color: ${props =>
      props.isDark ? props.theme.colors.dark2 : props.theme.colors.light2};
    color: ${props =>
      props.isDark ? props.theme.colors.light2 : props.theme.colors.dark2};
    font-weight: 600;
    // width: 100%;
    cursor: pointer;
    text-align: center;
    font-size: 0.8rem;
    :hover {
      border: 2px solid
        ${props =>
          props.isDark ? props.theme.colors.dark2 : props.theme.colors.light2};
      background-color: ${props =>
        props.isDark ? props.theme.colors.light2 : props.theme.colors.dark2};
      color: ${props =>
        props.isDark ? props.theme.colors.dark2 : props.theme.colors.light2};
    }
  }

  @media ${props => props.theme.breakpoints.tablet} {
    display: block;
    grid-column: 1 / span 2;
  }
  @media ${props => props.theme.breakpoints.mobile} {
  }
`
export const SnipcartSortButtonWrapper = styled.div`
  transform: ${props => props.isUp ? `rotate(0deg)`: `rotate(180deg)`};
`
export const SnipcartViewControllerWrapper = styled.div`
  .form-button {
    appearance: none;
    -webkit-appearance: none;
    border-radius: 2rem;
    padding: 10px;
    margin: 10px 0;
    border: 2px solid
      ${props =>
        props.isDark ? props.theme.colors.light2 : props.theme.colors.dark2};
    background-color: ${props =>
      props.isDark ? props.theme.colors.dark2 : props.theme.colors.light2};
    color: ${props =>
      props.isDark ? props.theme.colors.light2 : props.theme.colors.dark2};
    font-weight: 600;
    width: 100%;
    cursor: pointer;
    text-align: center;
    font-size: 0.8rem;
    :focus {
      border: 2px solid
        ${props =>
          props.isDark ? props.theme.colors.dark2 : props.theme.colors.light2};
      background-color: ${props =>
        props.isDark ? props.theme.colors.light2 : props.theme.colors.dark2};
      color: ${props =>
        props.isDark ? props.theme.colors.dark2 : props.theme.colors.light2};
    }
  }
  .edit {
    display: ${props => (props.show ? `block` : `none`)};
  }
`
