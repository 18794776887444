import styled from "styled-components"

export const QuickJumpTileWrapper = styled.div`
  display: grid;
  grid-template-columns: ${props =>
    props.backgroundImage ? "1fr 1fr" : "1fr"};
  border-radius: ${props => (props.backgroundImage ? "0rem" : "5rem")};
  font-weight: 700;
  font-size: 0.875rem;
  transition: filter 0.3s ease;
  justify-content: ${props =>
    props.backgroundImage ? "space-between" : "center"};
  background: ${props =>
    props.backgroundColor
      ? props.backgroundColor
      : props.isDark
      ? props.theme.colors.dark1
      : props.theme.colors.ed};
  h2 {
    width: ${props => (props.backgroundImage ? "100%" : "70%")};
    text-align: ${props => (props.backgroundImage ? "right" : "center")};
    font-size: ${props => (props.backgroundImage ? "3rem" : "3.5rem")};
    padding: 0.2rem;
  }
  &:hover,
  &:focus {
    filter: brightness(110%);
  }
  display: grid;
  height: 8rem;
  overflow: hidden;
  .LHSImage {
    display: ${props => (props.backgroundImage ? "grid" : "none")};
  }
  img {
    grid-column: 1;
    height: 8rem;
    top: 0;
    height: 8rem;
    width: auto;
  }
  h2 {
    width: 100%;
    grid-column: 2;
    font-size: ${props => (props.backgroundImage ? "0.8rem" : "1.1rem")};
  }
  .RHSText {
    padding: 0.5rem 0.75rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media ${props => props.theme.breakpoints.tabletLandscape} {
    height: 6rem;
    h2 {
      width: 100%;
      font-size: ${props => (props.backgroundImage ? "0.8rem" : "0.8rem")};
      text-align: center;
    }
  }
  @media ${props => props.theme.breakpoints.tablet} {
    height: 8rem;
    h2 {
      width: 100%;
      font-size: ${props => (props.backgroundImage ? "1rem" : "1.3rem")};
      text-align: center;
    }
  }

  @media ${props => props.theme.breakpoints.mobile} {
    display: grid;
    height: auto;
    grid-template-columns: ${props =>
      props.backgroundImage ? "1fr 1fr" : "1fr"};
    // h2 {
    //   width: 100%;
    //   font-size: ${props => (props.backgroundImage ? "1rem" : "1.3rem")};
    //   text-align: center;
    // }
    .LHSImage {
      display: grid;
    }
  }
`
export const QuickJumpTilingWrapper = styled.div`
padding: 0 ${props => props.theme.spacings.medium};

// padding: 0 2rem;
  grid-row: ${props => (props.row ? props.row : "1")};
  display: grid;
  row-gap: 0.5rem;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  column-gap: 0.5rem;
  a {
    text-decoration: none;
    color: inherit;
  }
  @media ${props => props.theme.breakpoints.tablet} {
    padding: 0;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    column-gap: 0.5rem;
  }

  @media ${props => props.theme.breakpoints.mobile} {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto auto;
  }
`
