import styled from "styled-components"
export const CritWrapper = styled.div`
.Header{
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.Heading{
  grid-column: 1;
}
.Controls{
  display: flex;
  justify-content:flex-end;
  align-items: center;
  grid-column: 2;
}
b{
  font-weight: 600;
}
.critForm{
}
.snappyOneLine{
  display: grid;
  grid-template-columns: 1fr 0.5rem 1fr;
  
}
.snappyOneLineLHS{
  grid-column: 1;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.snappyOneLineRHS{
  grid-column: 3;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
  color: ${props =>
    props.isDark ? props.theme.colors.light2 : props.theme.colors.dark2};
  background-color: ${props =>
    props.isDark ? props.theme.colors.dark2 : props.theme.colors.light2};
  .conferenceItem {
    padding-top: 3rem;
  }
  h1{
    font-size: 3rem;
  }
  h3 {
    font-size: 1.6rem;
    padding-top: 1rem;
  }
  h5 {
    padding-top: 1rem;
    font-size: 1.1rem;
  }
  p{
    padding-top: 0.5rem;
  }
`
export const CritFormWrapper = styled.div`
display: ${props => props.formOn ? `block` : `none`};
`