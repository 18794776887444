/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:d9a09a14-9304-4a3f-b380-8e54007af584",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_dDrbPQ13O",
    "aws_user_pools_web_client_id": "3gdk539h7afpqo4bvlehoe9f64",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://otvnkaop2jernmecszals72iqa.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AWS_IAM",
    "aws_user_files_s3_bucket": "gddashboard2c7acd2f869141d3966a8b4974451ca385314-dev",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
