const authResetSuccessReducer = (
  state = {
    isSendingCode: false,
    codeSent: false,
    isConfirming: false,
    userID: "",
    confirmed: false,
  },
  action
) => {
  switch (action.type) {
    case "AUTH_RESET_SUCCESS":
      return action.payload
    default:
      return state
  }
}

export default authResetSuccessReducer
