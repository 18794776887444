import styled from 'styled-components'
export const ContentWrapper = styled.main`
// display: none;
grid-column: 4 / span 8;
grid-row: 3 / span 5;
background-color: ${props => props.theme.colors.light2};
background-color: inherit;
padding: ${props => `${props.theme.spacings.xLarge} ${props.theme.spacings.xxLarge}`};
box-shadow: ${props => props.isDark ? props.theme.shadows.shadow2 : props.theme.shadows.shadow1};

z-index: 10;
h1{
  color: inherit;
}
p{
  color: inherit;
}
@media ${props => props.theme.breakpoints.tablet}{
  display: block;
  grid-column: 2 / span 6;
}

@media ${props => props.theme.breakpoints.mobile}{
  padding: ${props => `${props.theme.spacings.small} ${props.theme.spacings.small}`};
}
` 