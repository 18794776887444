const modalController = (
  state = {},
  action
) => {
  switch (action.type) {
    case "MODAL_CONTROLLER":
      return action.payload
    default:
      return state
  }
}

export default modalController
