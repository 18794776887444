import React, { useContext } from "react"
import { FormHRWrapper } from "../elements"
import { ThemeManagerContext } from "gatsby-styled-components-dark-mode"

export const FormHR = ({ HRName, row, lingua }) => {
  const themeContext = useContext(ThemeManagerContext)
  return (
    <FormHRWrapper row={row} isDark={themeContext.isDark}>
      <p> {!lingua ? "" : lingua}&nbsp;</p>
      <hr />
    </FormHRWrapper>
  )
}
export default FormHR
