import React from "react"
import { useSelector, connect } from "react-redux"
import { StoreEditButtonWrapper } from "../elements"
import { storeController, modalController } from "../state/actions"

export const SnipcartEdit = ({ locale, dispatch, item, isDark, store }) => {
  // const reduxModal = useSelector(state => state.modalController)
  // let modalState = { ...reduxModal }
  // if (modalState.id === item.id && modalState.action) {
  //   if (modalState.action) {
  // dispatch(deleteProduct(item, store))
  //   }
  // }
  // console.log('\n\nStoreEditButton\n\n')
  let id = item.id
  let visible = false

  const reduxControllerStore = useSelector(state => state.storeController)
  let reduxController = { ...reduxControllerStore }
  Object.keys(reduxController)
    .filter(key => key === id)
    .forEach(form => {
      visible = reduxController[form].show
    })

  const toggleEdit = viewID => {
    let controller = { ...reduxControllerStore }
    controller[id] = controller[id] || {}
    controller[id].edit = controller[id].edit || false
    controller[id].edit = !controller[id].edit
    dispatch(storeController(controller))
  }
  const callAlert = viewID => {
    let modal = {
      item: item,
      store: store,
      id: item.id,
      type: "yesno",

      title: "Really Delete?",
      content:
        "This action will permanently erase this product from your store.",
      action: "deleteSnipcartItem",
    }
    dispatch(modalController(modal))
  }

  return (
    <StoreEditButtonWrapper isDark={isDark} visible={visible}>
      <div
        className={"edit-form-button"}
        onClick={toggleEdit}
        onKeyPress={toggleEdit}
        role="button"
        tabIndex="0"
        >
        <h3>EDIT</h3>
      </div>
      <div
        className={"edit-form-button"}
        onClick={callAlert}
        onKeyPress={callAlert}
        role="button"
        tabIndex="0"
      >
        <h3>DELETE</h3>
      </div>
    </StoreEditButtonWrapper>
  )
}
const mapStateToProps = state => ({ localeString: state.local })
export default connect(state => mapStateToProps)(SnipcartEdit)
