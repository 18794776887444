import styled from "styled-components"
export const SnipcartDepartmentWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  grid-row: 4;
`

export const SnipcartButtonWrapperLight = styled.div`
  .snipcart-add-item {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    appearance: none;
    -webkit-appearance: none;
    border-radius: 2rem;
    padding: 10px;
    margin: 10px 0;
    border: 2px solid #414244;
    background-color: #f1f1f1;
    color: #414244;
    font-weight: 600;
    width: 10rem;
    cursor: pointer;
    text-align: center;
    :hover {
      // border: 2px solid #a1a2a4;
      background-color: #414244;
      color: #f1f1f1;
    }
  }
`

export const SnipcartButtonWrapperDark = styled.div`
.snipcart-add-item{
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    grid-row: 2;
    grid-column: 2;
    appearance: none;
    -webkit-appearance: none;
    border-radius: 2rem;
    padding: 10px;
    margin: 10px 0;
    border: 2px solid #f1f1f1;
    background-color: #414244;
    color: #f1f1f1;
    font-weight: 600;
    width: 10rem;
    cursor: pointer;
    text-align: center;
    :hover{
        border: 2px solid #f1f1f1;
        background-color: #f1f1f1;
        color: #414244;
    }
}
`

export const SnipcartButtonWrapper = styled.div``
export const SnipcartBasketWrapperLight = styled.div`
  text-align: center;
  .snipcart-checkout {
    background-color: rgb(65, 66, 68, 0.95);
    margin: 0;
    padding: 0;
    border: 0;
    cursor: pointer;
  }
`
export const SnipcartBasketWrapperDark = styled.div`
  text-align: center;
  .snipcart-checkout {
    background-color: rgb(241, 241, 241, 0.9);
    margin: 0;
    padding: 0;
    border: 0;
    cursor: pointer;
  }
`
export const SnipcartBasketWrapperProps = styled.div`
  h3 {
    font-size: 0.525rem;
    padding-bottom: 0.2rem;
  }
  color: ${props =>
    props.isDark ? props.theme.colors.light2 : props.theme.colors.dark2};
`

export const SnipcartWrapper = styled.div`
background-color: ${props => props.theme.colors.light2};
background-color: inherit;

grid-column: 1 / span 6;
// grid-column: 1 / span 2;
grid-row: 2;

display: block
  z-index: 1;
  padding: ${props =>
    `${props.theme.spacings.xLarge} ${props.theme.spacings.xxLarge}`};
    
    .waiting{
      padding: 1rem;
    }
  h1 {
    color: inherit;
  }
  p {
    color: inherit;
  }
  @media ${props => props.theme.breakpoints.tablet} {
    display: block;
    grid-column: 2 / span 6;
  }
  
  @media ${props => props.theme.breakpoints.mobile} {
    padding: ${props =>
      `${props.theme.spacings.small} ${props.theme.spacings.small}`};
  }
`
export const SnipcartCardWrapper = styled.div`
display: ${props => (props.visible ? `grid` : `none`)};

  width: 100%;
  box-shadow: ${props => props.isDark ? props.theme.shadows.shadow2 : props.theme.shadows.shadow1};
  margin: 1rem 0;
  padding: 1rem;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto auto auto auto;
  grid-row-gap: 1rem;
  grid-column-gap: 1rem;
  @media ${props => props.theme.breakpoints.tablet}{
  }
  @media ${props => props.theme.breakpoints.mobile}{
    .cardTitle{
      grid-row: 1;
      grid-column: 1 /span 2;
      display: flex;
      justify-content: center;
      align-items: center;

      h1{
        font-size: 1.5rem;
      }
    }
    .cardImage{
      grid-column: 1 / span 2;
      grid-row: 2;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
    .cardPitch {
      grid-column: 1 / span 2;
      grid-row: 3;
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
      p{
        font-size: 0.8rem;
      }
    }
    .unitPrice{
      grid-column: 1 / span 2;
      grid-row: 4;
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;      
    }
    .cardBuy {
      grid-row: 5;
      grid-column: 1 / span 2;
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
    }
  }
  `