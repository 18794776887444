import React from "react"
import { connect, useSelector } from "react-redux"

import { SnipcartDashboardWrapper } from "../elements"

import {
  SnipcartCard,
  SnipcartFormCreate,
  SnipcartFormUpdate,
  SnipcartViewController,
  SnipcartHR,
  SnipcartSortButton,
} from "../components"

import {
  // fetchStoreImage,
  snipcartCreate,
  getSnipcarts,
} from "../state/actions"

import { v4 as uuidv4 } from "uuid"

const SnipcartDashboard = ({ locale, dispatch, isDark }) => {
  const create_form_id=uuidv4()
  const optimist = useSelector(state => state.optimistSnipcart)
  const snipcartCreateRedux = useSelector(state => state.snipcartCreate)

  let snipcarts = []

  if (!optimist.items) {
    dispatch(getSnipcarts())
    return (
      <div>
        <h1>Waiting for store...</h1>
      </div>
    )
  }
  try {
    snipcarts = { ...optimist }.items
  } catch (error) {
    snipcarts = []
  }
  let buttonPlus = "+"
  let snipcartCreate_payload = { ...snipcartCreateRedux }
  if (snipcartCreate_payload.visible) {
    buttonPlus = "-"
  }

  const lingua = require("../utils/lingua")(locale)("Snipcart")
  const compare = (a, b) => {
    // Use toUpperCase() to ignore character casing
    const urlA = a.url.toUpperCase();
    const urlB = b.url.toUpperCase();
  
    let comparison = 0;
    if (urlA > urlB) {
      comparison = 1;
    } else if (urlA < urlB) {
      comparison = -1;
    }
    return comparison;
  }
  snipcarts.sort((a,b)=>{
    return a.sort - b.sort
  })
  snipcarts.sort(compare)
  const createState = evt => {
    if (evt.keyCode === 13 || evt.nativeEvent.type === "click") {
      snipcartCreate_payload.visible = snipcartCreate_payload.visible || false
      snipcartCreate_payload.visible = !snipcartCreate_payload.visible
      dispatch(snipcartCreate(snipcartCreate_payload))
    }
  }
  let url = ""
  const itemScript = item => {
    // console.log("item: ", item)
    if (item.url != url) {
      url = item.url
      return (
        <div key={uuidv4()}>
          <SnipcartHR key={uuidv4()} lingua={url} />
          <SnipcartViewController
            key={uuidv4()}
            item={item}
            store={snipcarts}
            isDark={isDark}
            tabIndex="0"
          />
          <SnipcartFormUpdate
            key={uuidv4()}
            isDark={isDark}
            item={item}
            locale={locale}
            store={snipcarts}
            tabIndex="0"
          />
          <SnipcartCard
            key={uuidv4()}
            isDark={isDark}
            item={item}
            locale={locale}
            tabIndex="0"
          />
        </div>
      )
    } else {
      return (
        <div key={uuidv4()}>
          <SnipcartViewController
            key={uuidv4()}
            item={item}
            store={snipcarts}
            isDark={isDark}
            tabIndex="0"
          />
          <SnipcartFormUpdate
            key={uuidv4()}
            isDark={isDark}
            item={item}
            locale={locale}
            store={snipcarts}
            tabIndex="0"
          />
          <SnipcartCard
            key={uuidv4()}
            isDark={isDark}
            item={item}
            locale={locale}
            tabIndex="0"
          />
        </div>
      )
    }
  }
  return (
    <SnipcartDashboardWrapper isDark={isDark}>
      <div className={"controlWrapper"}>
        <div className={"title"}>
          <h1>{lingua.snipcart}</h1>
        </div>
        {/* <div className={"sort-buttons"}>
          <SnipcartSortButton
            isUp={true}
            isDark={isDark}
            id={uuidv4()}
          />
          <SnipcartSortButton
            isUp={false}
            isDark={isDark}
            id={uuidv4()}
          />
        </div> */}
          <div
            className={"create-button"}
            onClick={createState}
            onKeyDown={createState}
            role="button"
            tabIndex="0"
          >
            <h2>{buttonPlus}</h2>
          </div>
        <div className={"createProduct"}>
          <SnipcartFormCreate
            isDark={isDark}
            id={create_form_id}
            locale={locale}
            snipcarts={snipcarts}
            tabIndex="0"
            store={snipcarts}
          />
        </div>
      </div>
      {snipcarts.map(item => {
        return itemScript(item)
      })}
      <h3>StoreFitter v0.1.08</h3>
    </SnipcartDashboardWrapper>
  )
}
const mapStateToProps = state => ({ localeString: state.local })
export default connect(state => mapStateToProps)(SnipcartDashboard)
