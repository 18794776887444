import React, { Component, createRef } from "react"
import { Hamburger2ItemWrapper } from "../elements"
import { Link } from "gatsby"
import { connect } from "react-redux"
import { hamburger, authRouter } from "../state/actions"

class Hamburger4Item extends Component {
  parentRef = createRef()
  render() {
    const isDark = this.props.isDark
    const first = this.props.burger.title
    const last = this.props.burger.contact
    const changeRedux = this.props.menu
    const tabIndex = changeRedux.showMenu2?'0':'-1'

    if (changeRedux.last && this.props.itemText === first) {
      this.parentRef.current.focus()
    }
    if (changeRedux.first && this.props.itemText === last) {
      this.parentRef.current.focus()
    }
    let state = { ...changeRedux }.showMenu2||false
    let shift = { ...changeRedux }.shift
    let redux = {
      showMenu2: true,
      tab: this.props.itemText,
      shift: shift,
    }
    const keyUp = evt => {
      if (evt.nativeEvent.key === "Shift") {
        shift = redux.shift = false
        transmit()
      }
    }
    const keyDown = evt => {
      if (evt.nativeEvent.key === "Shift") {
        shift = redux.shift = true
      }
      if (evt.nativeEvent.key === "Escape") {
        shift = redux.escape = true
      }
      if (
        evt.nativeEvent.key === "Tab" &&
        !shift &&
        this.props.itemText === last
      ) {
        redux.last = true
        evt.preventDefault()
      }
      if (
        evt.nativeEvent.key === "Tab" &&
        shift &&
        this.props.itemText === first
      ) {
        redux.first = true
        evt.preventDefault()
      }
      transmit()
    }

    const transmit = () => {
      this.props.hamburger(redux)
    }
    const dashboard = evt => {
      this.props.authRouter(this.props.action)
      
    }
    if (this.props.action){
      return (
        <Hamburger2ItemWrapper
          change={state}
          isDark={isDark}
          gridRow={this.props.gridRow}
          transition={this.props.transition}
        >
          <div
            className={"menuItem"}
            onKeyDown={keyDown}
            onKeyUp={keyUp}
            role="button"
            tabIndex='-1'
          >
            <div className={"transition"} onClick={dashboard}>
                <h1>{this.props.itemText}</h1>
            </div>
          </div>
        </Hamburger2ItemWrapper>
      )

    }else{

      return (
        <Hamburger2ItemWrapper
          change={state}
          isDark={isDark}
          gridRow={this.props.gridRow}
          transition={this.props.transition}
        >
        </Hamburger2ItemWrapper>
      )
    }
  }
}

const mapStateToProps = state => {
  return {
    menu: state.hamburger,
  }
}

const mapDispatchToProps = () => {
  return {
    hamburger,
    authRouter
  }
}

export default connect(mapStateToProps, mapDispatchToProps(), null, {
  forwardRef: true,
})(Hamburger4Item)
