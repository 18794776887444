import styled from "styled-components"
export const CardWrapper = styled.div`
  box-shadow: ${props => props.isDark ? props.theme.shadows.shadow2 : props.theme.shadows.shadow1};
  margin-top: 2rem;
  padding: 1rem;
  display: grid;
  grid-template-rows: 1fr;
  flex-direction: column;
  align-items: center;
  background: ${props => props.isDark ? props.theme.colors.dark2 : props.theme.colors.light2};
  color: ${props => props.isDark ? props.theme.colors.light2 : props.theme.colors.dark2};
  
  .topRow {
    display: grid;
    grid-row: 1;
    grid-template-columns: 1fr 1fr;
    column-gap: 0.5rem;
    .imageCell {
      grid-column: 1;
    }
    .heading {
      color: inherit;
      grid-column: 2;
      display: flex;
      flex-direction: column;
      align-items: left;
      justify-content: space-around;
      h2 {
        color: ${props => props.isDark ? props.theme.colors.light2 : props.theme.colors.dark2};
        margin: 0;
        font-weight: bold;
        text-align: left;
      }
      p {
        color: ${props => props.isDark ? props.theme.colors.light2 : props.theme.colors.dark2};
        margin: 0;
        font-size: 0.7rem;
      }
    }
  }
  .bottomRow {
    grid-row: 2;
    p {
      color: ${props => props.isDark ? props.theme.colors.light2 : props.theme.colors.dark2};
      margin-top: 0.4rem;
      text-align: left;
    }
  }

  a {
    text-decoration: none;
  }
`
