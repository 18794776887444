const optimist = (
  state = {},
  // state = [],
  action
) => {
  switch (action.type) {
    case "OPTIMIST":
      return action.payload
    default:
      return state
  }
}

export default optimist
