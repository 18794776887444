const imageDownloadReducer = (
  state = {
    file: {},
  },
  action
) => {
  switch (action.type) {
    case "IMAGE_DOWNLOAD":
      return action.payload
    default:
      return state
  }
}

export default imageDownloadReducer
