import { Auth, Storage, API, graphqlOperation } from "aws-amplify"
import awsmobile from "../../aws-exports"

import {
  listProducts as ListProducts,
  listSnipcarts as ListSnipcarts,
  // listTalks as ListTalks,
} from "../../graphql/queries"

import {
  // createTalk as CreateTalk,
  createProduct as CreateProduct,
  deleteProduct as DeleteProduct,
  updateProduct as UpdateProduct,
  createSnipcart as CreateSnipcart,
  deleteSnipcart as DeleteSnipcart,
  updateSnipcart as UpdateSnipcart,
} from "../../graphql/mutations"

let initializedStore = false
let initializedSnipcarts = false
// let auth = false
export const toggleDarkMode = () => {
  return {
    type: "DARK_MODE",
  }
}

export const switchLocale = (uia = "en") => {
  return {
    type: "LOCALE",
    payload: uia,
  }
}

export const consentGiven = (gdpr = false) => {
  return {
    type: "CONSENT",
    payload: gdpr,
  }
}

export const spinner = (spin = {}) => {
  console.log("spinner: ", spin)
  return {
    type: "SPINNER",
    payload: spin,
  }
}

export const basketTask = (item = {}) => {
  return {
    type: "BASKET",
    payload: item,
  }
}

export const loginStatus = (loggedIn = {}) => {
  return {
    type: "DASHBOARD",
    payload: loggedIn,
  }
}

export const login = (deets = "") => {
  return async dispatch => {
    try {
      dispatch(authFailure({ visible: false }))
      await Auth.signIn(deets.userName, deets.password)
      // dispatch(getStoreData())
      // auth = true
      // dispatch(loginStatus({ logger: true }))
      dispatch(spinner({ visible: false }))
      // dispatch(authRouter("WELCOME"))
      // dispatch(authRouter("STORE_FITTER"))
    } catch (error) {
      dispatch(authFailure({ message: "Login Failed", visible: true }))
      dispatch(spinner({ visible: false }))
    }
  }
}

export const authRouter = (root = "") => {
  return {
    type: "AUTH_ROUTER",
    payload: root,
  }
}
export const unAuthRouter = (root = "") => {
  return {
    type: "UN_AUTH_ROUTER",
    payload: root,
  }
}
export const logout = (resets = "") => {
  return async dispatch => {
    try {
      await Auth.signOut()
      // auth = false
      dispatch(loginStatus({ logger: false }))
    } catch (error) {
      dispatch(authFailure({ message: error.message }))
    }
  }
}

export const authFailure = (userID = "") => {
  return {
    type: "AUTH_FAILURE",
    payload: userID,
  }
}

export const resetStarted = (userID = "") => {
  return {
    type: "AUTH_RESET_STARTED",
    payload: userID,
  }
}

export const resetSuccess = (awsPayload = "") => {
  return {
    type: "AUTH_RESET_SUCCESS",
    payload: awsPayload,
  }
}

export const reset = (sub = "") => {
  return async dispatch => {
    dispatch(resetStarted({ userID: sub.userID }))
    try {
      await Auth.forgotPassword(sub.userID)
      dispatch(resetSuccess({ codeSent: true }))
      dispatch(spinner({ visible: false }))
      dispatch(authFailure({ visible: false }))
    } catch (error) {
      dispatch(spinner({ visible: false }))
      dispatch(authFailure({ message: error.message, visible: true }))
    }
  }
}

export const resetConfirmFailure = (userID = "") => {
  return {
    type: "AUTH_RESET_CONFIRM_FAILURE",
    payload: userID,
  }
}

export const resetConfirmStarted = (userID = "") => {
  return {
    type: "AUTH_RESET_CONFIRM_STARTED",
    payload: userID,
  }
}

export const resetConfirmSuccess = (awsPayload = "") => {
  return {
    type: "AUTH_RESET_CONFIRM_SUCCESS",
    payload: awsPayload,
  }
}

export const resetConfirm = (resets = "") => {
  return async dispatch => {
    dispatch(
      resetConfirmStarted({
        userID: resets.userID,
        code: resets.code,
        password: resets.password,
      })
    )
    dispatch(spinner({ visible: true }))
    try {
      await Auth.forgotPasswordSubmit(
        resets.userID,
        resets.code,
        resets.password
      )
      dispatch(
        resetConfirmSuccess({ message: "Password reset.", resetSuccess: true })
      )
      dispatch(spinner({ visible: false }))
      dispatch(authFailure({ visible: false }))
    } catch (error) {
      dispatch(
        authFailure({
          message:
            "Password must be 6 or more characters in lengths and must contain one number and one upper-case letter.",
          visible: true,
        })
      )
      dispatch(spinner({ visible: false }))
    }
  }
}

export const storeFailure = (userID = "") => {
  return {
    type: "STORE_FAILURE",
    payload: userID,
  }
}

export const dynamicImage = (imageObject = {}) => {
  return {
    type: "DYNAMIC_IMAGE",
    payload: imageObject,
  }
}
export const optimisticImage = (imageObject = {}) => {
  return {
    type: "OPTIMISTIC_IMAGE",
    payload: imageObject,
  }
}

export const optimisticUpload = (item, store) => {
  return async dispatch => {
    let path = item.pictureName
    let key = `images/${item.id}${path}`
    item.key = key
    try {
      dispatch(addImage2db(item, store))
      await Storage.put(key, item.imageFile, { contentType: item.type })
    } catch (error) {
      let modal = {
        type: "ok",
        title: "Upload Error",
        content: error.message,
        action: undefined,
      }
      dispatch(modalController(modal))
    }
  }
}

export const addImage2db = (item, store) => {
  const image = {
    id: item.id,
    pictureName: item.pictureName,
    pictureOwner: "owner",
    salesPitch: item.salesPitch,
    sasPOS: item.sasPOS,
    productTitle: item.productTitle,
    locale: item.locale,
    POS: item.POS,
    file: {
      bucket: awsmobile.aws_user_files_s3_bucket,
      region: awsmobile.aws_user_files_s3_bucket_region,
      key: `images/${item.id}${item.pictureName}`,
    },
  }
  return async dispatch => {
    try {
      store.push(image)
      dispatch(optimist({ items: store }))
      dispatch(storeCreate({ visible: false }))
      dispatch(storeController({ [item.id]: { edit: false, show: true } }))
      await API.graphql(graphqlOperation(CreateProduct, { input: image }))
    } catch (error) {
      let modal = {
        type: "ok",

        title: "Upload Error",
        content: error.message,
        action: undefined,
      }
      dispatch(modalController(modal))

      store.pop()
      dispatch(optimist({ items: store }))
      dispatch(storeCreate({ visible: false }))
    }
  }
}

export const itemAdded = (item = "") => {
  return {
    type: "ITEM_ADDED",
    payload: item,
  }
}

export const storeItems = (item = {}) => {
  return {
    type: "STORE_GET_ITEMS",
    payload: item,
  }
}

// export const getTalkData = (data = {}) => {
//   return async dispatch => {
//     try {
//       const talkData = await API.graphql(graphqlOperation(ListTalks))
//       let talksArray = talkData.data.listTalks.items
//       let testData = [...talksArray]
//       dispatch(talks({ items: testData }))
//     } catch (error) {
//       // console.log("getTalkData error: ", error)
//     }
//   }
// }

export const getStoreData = (data = {}) => {
  console.log("getStoreData")
  return async dispatch => {
    let storeData
    try {
      if (!initializedStore) {
        initializedStore = true
        // console.log("API call from getStoreData")
        storeData = await API.graphql(graphqlOperation(ListProducts))
        let items = {}
        let itemImages = {}
        storeData.data.listProducts.items.forEach(item => {
          items[item.id] = item
          itemImages[item.id] = {
            id: item.id,
            file: `https://${awsmobile.aws_user_files_s3_bucket}.s3.${
              awsmobile.aws_user_files_s3_bucket_region
            }.amazonaws.com/public/images/${item.id + item.pictureName}`,
            label: item.pictureName,
          }
        })
        dispatch(formInputs({ items: items }))
        dispatch(optimist(storeData.data.listProducts))
        // console.log('store itemImages: ',itemImages)

        //___________________________________________XXXXx
        dispatch(optimisticImage(itemImages))
      }
    } catch (error) {
      console.log("getStoreData error: ", error)
    }
  }
}

export const optimist = (items = {}) => {
  return {
    type: "OPTIMIST",
    payload: items,
  }
}

export const storeAPICall = () => {
  return async dispatch => {
    try {
      // console.log('API call from storeAPICall')
      const storeData = await API.graphql(graphqlOperation(ListProducts))
      dispatch(storeItems(storeData))
    } catch (error) {
      // console.log("APICallError: ", error)
    }
  }
}

export const fetchStoreImage = (key = "") => {
  let image = {}
  return async dispatch => {
    try {
      // console.log("API call from fetchStoreImage")
      image.data = await Storage.get(key)
      image.key = key
      dispatch(imageDownload(image))
    } catch (error) {
      // console.log("fetchStoreImage error: ", error)
    }
  }
}

export const imageDownload = (item = {}) => {
  return {
    type: "IMAGE_DOWNLOAD",
    payload: item,
  }
}

export const authenticationStatus = () => {
  return async dispatch => {
    try {
      let authenticatedUser = await Auth.currentAuthenticatedUser()
      let spread = { ...authenticatedUser.attributes }
      spread.id = spread.sub
      // dispatch(loginStatus({logger: true, metaData: spread}))
    } catch (error) {
      // console.log("authenticationStatus error: ", error)
    }
  }
}

// export const addCrit = (data = {}, crit = {}) => {
//   return async dispatch => {
//     try {
//       // testData = data
//       dispatch(talks({ items: data }))
//       await API.graphql(graphqlOperation(CreateTalk, { input: crit }))
//       dispatch(formInputs({ items: {} }))
//       dispatch(critForm())
//     } catch (error) {
//       // console.log("CreateTalk error: ", error.errors)
//     }
//   }
// }

export const talks = (data = []) => {
  return {
    type: "TALKS",
    payload: data,
  }
}

export const critForm = () => {
  return {
    type: "CRIT_FORM",
  }
}

export const formInputs = (item = "") => {
  return {
    type: "FORM_ITEMS",
    payload: item,
  }
}

export const storeController = (item = {}) => {
  return {
    type: "STORE_CONTROLLER",
    payload: item,
  }
}

export const storeCreate = (item = {}) => {
  return {
    type: "STORE_CREATE",
    payload: item,
  }
}

export const modalController = (item = {}) => {
  return {
    type: "MODAL_CONTROLLER",
    payload: item,
  }
}

export const hamburger = (item = false) => {
  return {
    type: "HAMBURGER",
    payload: item,
  }
}

export const storeAction = (item = {}) => {
  return {
    type: "STORE_ACTION",
    payload: item,
  }
}

export const deleteStoreItem = (item, store) => {
  return async dispatch => {
    let key = `images/${item.id}${item.pictureName}`
    try {
      dispatch(removeProductDB({ id: item.id }))
      store.forEach((product, index) => {
        if (product.id === item.id) {
          store.splice(index, 1)
        }
      })
      dispatch(optimist({ items: store }))
      // console.log("API call from deleteImage")
      await Storage.remove(key)
    } catch (error) {
      let modal = {
        type: "ok",
        title: "Image Delete Error",
        content: error.message,
        action: undefined,
      }
      dispatch(modalController(modal))
    }
  }
}

export const removeProductDB = (item = "") => {
  return async dispatch => {
    try {
      // console.log("API call from deleteAPI record")
      await API.graphql(graphqlOperation(DeleteProduct, { input: item }))
    } catch (error) {
      let modal = {
        type: "ok",
        title: "Remove Product Error",
        content: error.message,
        action: undefined,
      }
      dispatch(modalController(modal))
    }
  }
}

export const optimisticUpdate = (item, store) => {
  return async dispatch => {
    let APIItem = { ...item }
    delete APIItem.createdAt
    delete APIItem.updatedAt
    delete APIItem.owner
    delete APIItem.checkedValue
    delete APIItem.oldPictureName
    delete APIItem.imageFile
    delete APIItem.type
    store.forEach((toUpdate, index) => {
      if (item.id === toUpdate.id) {
        store[index] = item
      }
    })
    // console.log("db: ", store)
    try {
      let redux = { items: store }
      dispatch(optimist(redux))
      dispatch(storeController({ [item.id]: { edit: false, show: true } }))
      if (item.oldPictureName) {
        dispatch(deleteImage(item))
        delete item.oldPictureName
      }
      // console.log("API call from update")
      await API.graphql(graphqlOperation(UpdateProduct, { input: APIItem }))
    } catch (error) {
      let modal = {
        type: "ok",
        title: "Update Error",
        content: error.message,
        action: undefined,
      }
      dispatch(modalController(modal))
    }
  }
}

export const deleteImage = item => {
  // console.log("delete")
  return async dispatch => {
    let key = `images/${item.id}${item.oldPictureName}`
    try {
      // console.log("API call from deleteImage: ",key)
      await Storage.remove(key)
      dispatch(addImage(item))
    } catch (error) {
      let modal = {
        type: "ok",
        title: "Image Delete Error",
        content: error.message,
        action: undefined,
      }
      dispatch(modalController(modal))
    }
  }
}

export const addImage = item => {
  return async dispatch => {
    let path = item.pictureName
    let key = `images/${item.id}${path}`
    item.key = key
    try {
      await Storage.put(key, item.imageFile, { contentType: item.type })
    } catch (error) {
      let modal = {
        type: "ok",
        title: "Add Image Error",
        content: error.message,
        action: undefined,
      }
      dispatch(modalController(modal))
    }
  }
}
export const chat = (chatting = {}) => {
  return {
    type: "CHAT",
    payload: chatting,
  }
}

export const optimisticUploadSnipcart = (item, store) => {
  return async dispatch => {
    let path = item.pictureName
    let key = `images/${item.id}${path}`
    item.key = key
    try {
      dispatch(Snipcart(item, store))
      await Storage.put(key, item.imageFile, { contentType: item.type })
    } catch (error) {
      let modal = {
        type: "ok",
        title: "Upload Error",
        content: error.message,
        action: undefined,
      }
      dispatch(modalController(modal))
    }
  }
}

export const Snipcart = (item, store) => {
  // let id = uuidv4()
  console.log("id: ", item.id)
  const image = {
    id: item.id,
    price: item.price * 100,
    url: item.url,
    description: item.description,
    name: item.name,
    taxes: item.taxes,
    disabled: item.disabled,
    locale: item.locale,
    sort: item.sort,
    file: {
      bucket: awsmobile.aws_user_files_s3_bucket,
      region: awsmobile.aws_user_files_s3_bucket_region,
      key: `images/${item.id + item.pictureName}`,
    },
  }
  return async dispatch => {
    try {
      console.log("image: ", image)
      store.push(image)
      console.log("store: ", store)
      dispatch(optimistSnipcart({ items: store }))
      dispatch(storeCreate({ visible: false }))
      dispatch(storeController({ [item.id]: { edit: false, show: true } }))

      await API.graphql(graphqlOperation(CreateSnipcart, { input: image }))
    } catch (error) {
      // let modal = {
      //   type: "ok",
      //   title: "Upload Error",
      //   content: error.message,
      //   action: undefined,
      // }
      // dispatch(modalController(modal))
      // store.pop()
      // dispatch(optimist({ items: store }))
      // dispatch(storeCreate({ visible: false }))
    }
  }
}
export const getSnipcarts = (data = {}) => {
  return async dispatch => {
    let storeData
    try {
      if (!initializedSnipcarts) {
        initializedSnipcarts = true
        storeData = await API.graphql(graphqlOperation(ListSnipcarts))
        let items = {}
        let itemImages = {}
        storeData.data.listSnipcarts.items.forEach(item => {
          item.price = item.price / 100
          // console.log('item: ',item)
          items[item.id] = item
          itemImages[item.id] = {
            id: item.id,
            file: `https://${awsmobile.aws_user_files_s3_bucket}.s3.${awsmobile.aws_user_files_s3_bucket_region}.amazonaws.com/public/${item.file.key}`,
            label: item.pictureName,
          }
        })
        dispatch(snipcartFormInputs({ items: items }))
        // console.log('snipcart itemImages: ',storeData.data.listSnipcarts)
        dispatch(optimistSnipcart(storeData.data.listSnipcarts))
        dispatch(snipcartImage(itemImages))
      }
    } catch (error) {
      console.log("getSnipcarts error: ", error)
    }
  }
}
export const snipcartCreate = (item = {}) => {
  return {
    type: "SNIPCART_CREATE",
    payload: item,
  }
}

export const optimistSnipcart = (items = {}) => {
  return {
    type: "OPTIMIST_SNIPCART",
    payload: items,
  }
}
export const snipcartSites = (items = {}) => {
  return {
    type: "SNIPCART_SITES",
    payload: items,
  }
}
export const snipcartImage = (imageObject = {}) => {
  return {
    type: "SNIPCART_IMAGE",
    payload: imageObject,
  }
}

export const deleteSnipcartItem = (item, store) => {
  return async dispatch => {
    let key = `images/${item.id}${item.pictureName}`
    try {
      dispatch(removeSnipcartDB({ id: item.id }))
      store.forEach((product, index) => {
        if (product.id === item.id) {
          store.splice(index, 1)
        }
      })
      dispatch(optimistSnipcart({ items: store }))
      // console.log("API call from deleteImage")
      await Storage.remove(key)
    } catch (error) {
      let modal = {
        type: "ok",
        title: "Image Delete Error",
        content: error.message,
        action: undefined,
      }
      dispatch(modalController(modal))
    }
  }
}

export const removeSnipcartDB = (item = "") => {
  return async dispatch => {
    try {
      // console.log("API call from deleteAPI record")
      await API.graphql(graphqlOperation(DeleteSnipcart, { input: item }))
    } catch (error) {
      let modal = {
        type: "ok",
        title: "Remove Product Error",
        content: error.message,
        action: undefined,
      }
      dispatch(modalController(modal))
    }
  }
}

export const snipcartUpdate = (item, store) => {
  return async dispatch => {
    let APIItem = { ...item }
    delete APIItem.createdAt
    delete APIItem.updatedAt
    delete APIItem.owner
    delete APIItem.checkedValue
    delete APIItem.oldPictureName
    delete APIItem.imageFile
    delete APIItem.type
    APIItem.price = APIItem.price * 100
    store.forEach((toUpdate, index) => {
      if (item.id === toUpdate.id) {
        store[index] = item
      }
    })
    // console.log("db: ", store)
    try {
      let redux = { items: store }
      dispatch(optimist(redux))
      dispatch(storeController({ [item.id]: { edit: false, show: true } }))
      if (item.oldPictureName) {
        dispatch(deleteImage(item))
        delete item.oldPictureName
      }
      // console.log("API call from update")
      await API.graphql(graphqlOperation(UpdateSnipcart, { input: APIItem }))
    } catch (error) {
      let modal = {
        type: "ok",
        title: "Update Error",
        content: error.message,
        action: undefined,
      }
      dispatch(modalController(modal))
    }
  }
}
export const snipcartFormInputs = (item = "") => {
  return {
    type: "SNIPCART_FORM_ITEMS",
    payload: item,
  }
}
