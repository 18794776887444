import styled from "styled-components"
export const ResetWrapper = styled.div`
  // width: 20rem;
  .reset {
    font-size: 1rem;
    text-align: left;
    cursor: pointer;
  }
`

export const AuthWrapper = styled.div`
height: 100%;
display: grid;
grid-template-rows: auto auto auto;
.pos-one {
  grid-row: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.pos-two {
  grid-row: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.auth {
  max-width: 22rem;
  width: 100%;
}
.pos-three {
  grid-row: 3;
}
`
export const LogInWrapper = styled.div`
  grid-row: 2;
  .reset {
    max-width: 22rem;
    width: 100%;
  }
  .formElement {
  }
  a {
    text-decoration: none;
  }
  .gotoReset {
  }
  .rounded-input {
    padding: 10px;
    border: 0;
    box-shadow: 0 0 15px 4px rgba(0, 0, 0, 0.06);
    padding: 10px;
    border-radius: 2rem;
    margin: 10px 0;
    width: 100%;
  }
  .rounded-area {
    padding: 10px;
    border: 0;
    box-shadow: 0 0 15px 4px rgba(0, 0, 0, 0.06);
    padding: 10px;
    border-radius: 0.5rem;
    margin: 10px 0;
    width: 100%;
    height: 3rem;
  }
  .form-button {
    appearance: none;
    -webkit-appearance: none;
    border-radius: 2rem;
    padding: 10px;
    margin: 10px 0;
    border: 2px solid
      ${props =>
        props.isDark ? props.theme.colors.light2 : props.theme.colors.dark2};
    background-color: ${props =>
      props.isDark ? props.theme.colors.dark2 : props.theme.colors.light2};
    color: ${props =>
      props.isDark ? props.theme.colors.light2 : props.theme.colors.dark2};
    font-weight: 600;
    width: 100%;
    cursor: pointer;
  }
  .form-button:hover {
    border: 2px solid
      ${props =>
        props.isDark ? props.theme.colors.dark2 : props.theme.colors.light2};
    background-color: ${props =>
      props.isDark ? props.theme.colors.light2 : props.theme.colors.dark2};
    color: ${props =>
      props.isDark ? props.theme.colors.dark2 : props.theme.colors.light2};
  }
  @media ${props => props.theme.breakpoints.mobile} {
  }
`
