import styled from 'styled-components'
export const ButtonFleetWrapper = styled.div`
  justify-content: space-between;
  h3 {
    font-size: 1rem;
  }
  .buyButton {
    appearance: none;
    -webkit-appearance: none;
    border-radius: 2rem;
    padding: 10px;
    margin: 10px 0;
    border: 2px solid
      ${props =>
        props.isDark ? props.theme.colors.light2 : props.theme.colors.dark2};
    background-color: ${props =>
      props.isDark ? props.theme.colors.dark2 : props.theme.colors.light2};
    color: ${props =>
      props.isDark ? props.theme.colors.light2 : props.theme.colors.dark2};
    font-weight: 600;
    width: 10rem;
    cursor: pointer;
    text-align: center;
  }
  .buyButton:hover {
    border: 2px solid
      ${props =>
        props.isDark ? props.theme.colors.dark2 : props.theme.colors.light2};
    background-color: ${props =>
      props.isDark ? props.theme.colors.light2 : props.theme.colors.dark2};
    color: ${props =>
      props.isDark ? props.theme.colors.dark2 : props.theme.colors.light2};
    }
`