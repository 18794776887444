const authResetStartedConfirmReducer = (
  state = {
    userID: "",
    code: "",
    password: "",
  },
  action
) => {
  switch (action.type) {
    case "AUTH_RESET_CONFIRM_STARTED":
      return action.payload
    default:
      return state
  }
}

export default authResetStartedConfirmReducer
