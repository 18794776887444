import React from "react"
import { HeaderWrapper } from "../elements"
import { Nav } from "../components"
export const Header = ({location, url}) => {
  return (
    <HeaderWrapper>
      <Nav location={location} url={url}/>
    </HeaderWrapper>
  )
}
