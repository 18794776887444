import React from "react"
import { ResetRequest, ResetConfirm, ResetSuccess } from "../components"
import { 
  // AuthWrapper 
} from "../elements"
// import { loginStatus } from "../state/actions"
import { 
  // connect, 
  useSelector 
} from "react-redux"
import { 
  // loginStatus 
} from "../state/actions"

export const Create = ({ isDark, locale, location }) => {
  let codeSent = useSelector(state => state.resetSuccess).codeSent
  let success = useSelector(state => state.resetConfirmSuccess).resetSuccess
  return !codeSent ? (
    <ResetRequest isDark={isDark} locale={locale} />
  ) : !success ? (
    <ResetConfirm isDark={isDark} locale={locale} />
  ) : (
    <ResetSuccess isDark={isDark} locale={locale} location={location} />
  )
}