import React, { 
  useState, 
  // useEffect, 
  useContext 
} from "react"
import { useStaticQuery, graphql } from "gatsby"

import {
  SnipcartBasketWrapperLight,
  SnipcartBasketWrapperDark,
  SnipcartBasketWrapperProps,
} from "../elements"

import { SnipcartContext } from "gatsby-plugin-snipcart-advanced/context"
import { ThemeManagerContext } from "gatsby-styled-components-dark-mode"

import { useSelector } from "react-redux"

export const SnipcartBasket = ({ location }) => {
  const change = useSelector(state => state.hamburger)
  // let basketState = { ...change }.showMenu2
  const authRouter = useSelector(state => state.authRouter)
  const url = location.split("/")

  const { state } = useContext(SnipcartContext)
  const { cartTotal, cartQuantity } = state
  const [open, toggleOpen] = useState(false)
  const [item, setItem] = useState({})
  const themeContext = useContext(ThemeManagerContext)
  const isDark = themeContext.isDark

  const totalFloat = (cartTotal ).toFixed(2)
  const total = Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "gbp",
  }).format(totalFloat)

  // useEffect(() => {
  //   const { Snipcart } = window
  //   if (!Snipcart) return
  //   Snipcart.events.on("item.adding", _item => {})
  // }, [])

  const data = useStaticQuery(graphql`
    query {
      dark: file(relativePath: { eq: "basket_dark.svg" }) {
        publicURL
      }
      light: file(relativePath: { eq: "basket_light.svg" }) {
        publicURL
      }
    }
  `)
  let basket = isDark ? data.light.publicURL : data.dark.publicURL

  if (url[2] === "dashboard" && authRouter !== "LOGIN") {
    return null
  } else {
    if (isDark) {
      return (
        <div>
          <SnipcartBasketWrapperLight
            className={"snipcart-overwrite"}
          >
            <button className={"snipcart-checkout"}>
              <img src={basket} alt={"Shopping Basket"} />
            </button>
          </SnipcartBasketWrapperLight>
          <SnipcartBasketWrapperProps isDark={isDark}>
            <h3>Quantity: {cartQuantity}</h3>
            <h3>Total: {total}</h3>
          </SnipcartBasketWrapperProps>
        </div>
      )
    } else {
      return (
        <div>
          <SnipcartBasketWrapperDark
            className={"snipcart-overwrite"}
          >
            <button className={"snipcart-checkout"}>
              <img src={basket} alt={"Shopping Basket"} />
            </button>
          </SnipcartBasketWrapperDark>
          <SnipcartBasketWrapperProps isDark={isDark}>
            <h3>Quantity: {cartQuantity}</h3>
            <h3>Total: {total}</h3>
          </SnipcartBasketWrapperProps>
        </div>
      )
    }
  }
}
export default SnipcartBasket
