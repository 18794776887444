import styled from "styled-components"
export const ModalWrapper = styled.div`
  z-index: 1000;
  position: fixed;
  top: -${props => props.theme.navHeight.singleLine};
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: ${props => (props.displayModal ? `flex` : `none`)};
  // display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;

  h3 {
    font-size: 0.8rem;
    font-weight: 800;
  }
  h1 {
    font-size: 2rem;
    font-weight: 400;
    text-align: center;
  }
  .modal-main {
    display: grid;
    grid-template-rows: auto auto auto;
    grid-row-gap: 1rem;
    // grid-template-columns: 1fr 1fr;
    background: ${props =>
      props.isDark ? props.theme.colors.dark2 : props.theme.colors.light2};
    color: ${props =>
      props.isDark ? props.theme.colors.light2 : props.theme.colors.dark2};
    width: 40%;
    height: auto;
    border-radius: 2rem;
    padding: 4rem 2rem;
  }
  .title {
    border-radius: 2rem;
    text-align: center;
    grid-row: 1;
    // grid-column: 1 / span 2;
    display: flex;
    align-items: center;
    justify-content: center;
    background: red;
    color: black;
    padding: 1rem 0;
  }
  .content {
    grid-row: 2;
    // grid-column: 1 / span 2;
    // padding-bottom: 1rem;
    display: flex;
    align-items: center;
  }

  .buttons {
    display: grid;
    grid-column-gap: 2rem;
    grid-template-columns: 1fr 1fr;
  }

  .ok {
    grid-column: 2;
    grid-row: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .cancel {
    grid-column: 1;
    grid-row: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .form-button {
    appearance: none;
    -webkit-appearance: none;
    border-radius: 2rem;
    padding: 10px;
    margin: 10px 0;
    border: 2px solid
      ${props =>
        props.isDark ? props.theme.colors.light2 : props.theme.colors.dark2};
    background-color: ${props =>
      props.isDark ? props.theme.colors.dark2 : props.theme.colors.light2};
    color: ${props =>
      props.isDark ? props.theme.colors.light2 : props.theme.colors.dark2};
    min-width: 5rem;
    cursor: pointer;
    text-align: center;
  }
  .form-button:hover {
    border: 2px solid
      ${props =>
        props.isDark ? props.theme.colors.dark2 : props.theme.colors.light2};
    background-color: ${props =>
      props.isDark ? props.theme.colors.light2 : props.theme.colors.dark2};
    color: ${props =>
      props.isDark ? props.theme.colors.dark2 : props.theme.colors.light2};
  }

  @media ${props => props.theme.breakpoints.tablet} {
    .modal-main {
      width: 50%;
      padding: 2rem 1.5rem;
    }
  }
  @media ${props => props.theme.breakpoints.mobile} {
    .title {
      padding: 0.5rem;
    }
    .modal-main {
      grid-template-rows: 1fr auto 1fr;
      padding: 1rem 1rem;
      width: 80%;
      grid-row-gap: 1rem;
    }
  }
`
