const authRouter = (
  state = "LOGIN",
  action
) => {
  switch (action.type) {
    case "AUTH_ROUTER":
      return action.payload
    default:
      return state
  }
}

export default authRouter
