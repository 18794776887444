import React from "react"
import { SideBarWrapper } from "../elements"
// import { useStaticQuery, graphql, Link } from "gatsby"
import { Link } from "gatsby"

// import { galleries } from "../state/actions"

export const SideBarLeft = ({ isDark, locale, location, url }) => {

  let contact = url + "/" + locale + "/contact"
  let gallery = url + "/" + locale + "/gallery"
  const linguine = require("../utils/lingua")(locale)
  let navText = linguine("Hamburger")
  return (
    <SideBarWrapper isDark={isDark} left={true}>
      <div className={"menu"}>
        <div className={"menuItem"}>
          <a href={gallery}>
            <h2>{navText.gallery}</h2>
          </a>
        </div>
        <div className={"menuItem"}>
          <a href={contact}>
            <h2>{navText.contact}</h2>
          </a>
        </div>
      </div>
    </SideBarWrapper>
  )
}

export default SideBarLeft
