import React from "react"
import { TopWrapper } from "../elements"
export const Top = ({ children }) => {
  return (
    <TopWrapper>
      {children}
    </TopWrapper>
  )
}
export default Top
