import React, { useContext } from "react"
import { ThemeManagerContext } from "gatsby-styled-components-dark-mode"
import { DarkModeWrapper } from "../elements"
const DarkMode = () => {
  const themeContext = useContext(ThemeManagerContext)
  const toggle = () => {
    themeContext.toggleDark()
  }
  const keyPress = (evt) => {
    if (evt.key==='Enter'){
      themeContext.toggleDark()
    }
  }
  return (
    <DarkModeWrapper onClick={() => toggle()}>
      <div 
        onKeyPress={keyPress}
        role='button'
        tabIndex="0"
      >
      <h1 >{themeContext.isDark ? "🌝" : "🌒" }</h1>
      </div>
    </DarkModeWrapper>
  )
}
export default DarkMode
